<template>
  <div>
    <!-- <div
      class="littleTit"
      style="color: rgba(0, 0, 0, 0.8); font-weight: 700; font-size: 15px"
    >
      文件上传
    </div> -->
    <div id="uploading" style="padding: 0">
      <!-- 修改区间 -->
      <label>文件名：</label>
      <el-input
        placeholder="请输入内容"
        v-model="input"
        :disabled="true"
        style="width: 38%; height: 40px"
      />
      <!-- @click="dialogVisible = true" -->
      <span
        class="example"
        @click="openModal"
        @mouseover="showDiv2"
        @mouseout="hideDiv2"
        style="position: relative"
        >选择文件
        <div
          v-show="showSecondDiv"
          class="bottomTips"
          style="
            position: absolute;
            top: 36px;
            text-wrap: wrap;
            width: 450px;
            left: -269px;
            z-index: 10000;
            background: black;
            color: #fff;
            padding: 5px;
            margin-right: 32px;
            border-radius: 6px;
          "
        >
          <div class="tips">
            强烈推荐优先将本地数据直接复制粘贴（Ctrl
            C+V）到下方表格文字框进行上传，文件上传容易因未知格式报错！！！
          </div>
        </div>
      </span>
      <Examples v-if="isExample" :xlsxData="datas"></Examples>
      <el-dialog title="上传文件" :visible.sync="dialogVisible" width="30%">
        <div class="upload" style="margin-bottom: 20px">
          <div class="dropzone" v-for="(item, i) in upName" :key="i">
            <label class="choseFile">{{ item }}：</label>
            <input
              class="input11"
              type="file"
              ref="file"
              @change="onFileChange('file' + (i + 1))"
            />
          </div>
        </div>
        <el-button class="but" type="primary" @click="uploadFile"
          >上传</el-button
        >
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Loading, Message } from "element-ui";
import Examples from "../Examples.vue";
export default {
  components: {
    Examples,
  },
  props: {
    interfaces: {
      type: String,
    },
    upName: {
      type: Array,
      default: () => ["选择文件一", "选择文件二", "选择文件三"],
    },
    params: {
      type: Object,
      default: () => ({
        file1: null,
        file2: null,
        file3: null,
        projectname: "",
      }),
    },
    datas: {
      type: Array,
    },
    isExample: {
      type: Boolean,
      default: true,
    },
    pid: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      input: "",
      dialogVisible: false,
      loadinginstace: null,
      listsData: [],
      file1: null,
      file2: null,
      file3: null,
      showSecondDiv: false,
    };
  },
  methods: {
    openModal() {
      this.dialogVisible = true;
    },
    showDiv2() {
      this.showSecondDiv = true;

      // console.log(666, this.showSecondDiv);
    },
    hideDiv2() {
      this.showSecondDiv = false;
    },
    onFileChange(file) {
      if (file == "file1") {
        this.$props.params.file1 = event.target.files[0];
        this.file1 = event.target.files[0];
      } else if (file == "file2") {
        this.$props.params.file2 = event.target.files[0];
        this.file2 = event.target.files[0];
      } else {
        this.$props.params.file3 = event.target.files[0];
        this.file3 = event.target.files[0];
      }
    },
    uploadFile() {
      if (this.file1 != null && this.file2 != null && this.file3 != null) {
        this.dialogVisible = false;
        let t;
        let xhr = null;
        let time = 60;
        this.loadinginstace = Loading.service({
          lock: true,
          text: "正在处理，请稍后。。。",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.5)",
        });
        t = setInterval(() => {
          time--;
          if (time > 0) {
            switch (true) {
              case time <= 30 && 0 < time:
                this.loadinginstace.text =
                  "您提交的数据量较大，请耐心等待。。。";
                break;
              default:
                break;
            }
          } else {
            clearInterval(t);
            // if (xhr) {
            //     xhr.abort();
            // }
            this.loadinginstace.close();

            this.$confirm("正在处理，可在我的项目里获取结果", "提示", {
              confirmButtonText: "前往我的项目",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$message({
                  type: "success",
                  message: "前往我的项目",
                });
              })
              .catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '已取消删除'
                // });
              });
          }
        }, 1000);

        let params = {
          ...this.$props.params,
          file1: this.file1,
          file2: this.file2,
          file3: this.file3,
          nocover: 1,
        };
        if (params.setGraph) {
          params.setGraph = JSON.stringify(this.params.setGraph);
        }
        this.$post("/upload/" + this.$route.query.pid, params)
          .then((response) => {
            clearInterval(t);
            this.loadinginstace.close();
            console.log(response); //后台返回的数据
            sessionStorage.setItem("Symble", 1);
            if (
              response.data.error == "User Logged in elsewhere" ||
              response.data.error == "User Not Login"
            ) {
              this.$message({
                showClose: false,
                message: response.data.error,
                type: "error",
              });
              this.$router.push("/");
            } else {
              if (response.data.error.length > 0) {
                this.$message({
                  showClose: false,
                  message: response.data.error,
                  type: "error",
                });
              } else if (response.data.error.msg) {
                this.$message({
                  showClose: false,
                  message: response.data.error.msg,
                  type: "error",
                });
              } else {
                this.listsData = response.data;
                this.$emit("handleSuccess", this.listsData);
              }
            }
          })
          .catch((error) => {
            clearInterval(t);
            this.loadinginstace.close();
            if (xhr) {
              xhr.abort(); // Abort the request if it's still ongoing
            }
          });
      } else {
        this.$message({
          showClose: true,
          message: "请先选择上传文件",
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#uploading {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .example {
    display: inline-block;
    padding: 5px 8px;
    font-size: 14px;
    border: none;
    vertical-align: top;
    height: 32px;
    color: #fff;
    background-color: #0294a7;
    border-color: #026c7a;
    -webkit-box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
  }
  .dropzone {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
  }

  .el-button {
    margin-left: 10px;
    width: 90px;
    padding: 5px 8px;
    height: 34px;
    color: #fff;
    background-color: #28a1b1;
  }

  .example1 {
    width: 80px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 13px;
  }
}
</style>