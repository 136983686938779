<template>
  <div
    style="
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    "
  >
    <label class="labelFontSize item">图形大小:</label>
    <el-input
      v-model="inputWidth"
      @change="getvalue1()"
      placeholder="宽"
    ></el-input>
    <el-input
      v-model="inputHeight"
      @change="getvalue2()"
      placeholder="高"
    ></el-input>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inputWidth: null,
      inputHeight: null,
    };
  },
  methods: {
    getvalue1() {
      this.$emit("drawChart1", this.inputWidth); // 通过 input 事件更新父组件的数据

      //   this.$emit("drawChart"); // 触发自定义事件
    },
    getvalue2() {
      this.$emit("drawChart2", this.inputHeight); // 通过 input 事件更新父组件的数据

      //   this.$emit("drawChart"); // 触发自定义事件
    },
  },
};
</script>

<style>
</style>