<template>
    <div class="adjustall">
        <el-tabs type="border-card" class="adjust" v-model="internalActiveName" @tab-click="handleClick">
            <slot name="params"></slot>
            <div v-if="internalActiveName == 'params'"
                style="display:flex;align-items:center;justify-content:space-between;position:relative;">
                <div></div>
                <el-button type="primary" @click="internalActiveName = 'upload'">下一步</el-button>
            </div>
            <slot name="upload"></slot>
            <slot name="chartSet"></slot>
            <el-tab-pane label="说明">
                <Description></Description>
            </el-tab-pane>
            <!-- <el-tab-pane label="讨论区"> 
                <Talking />
            </el-tab-pane> -->
        </el-tabs>
    </div>
</template>

<script>
export default {
    props: {
        activeName: {
            type: String,
        },
        name: {
            type: String
        }
    },
    data() {
        return {
            internalActiveName: this.activeName,
            label: this.name,
            status: true,
        };
    },
    created(){
        console.log(this.activeName)
        if(this.activeName!==undefined&&this.activeName!='upload'){
            this.internalActiveName='upload'
        }
        if(this.activeName=='upload'){
            this.status=false
        }
    },
    mounted(){
        this.$nextTick(()=>{
            if(this.activeName!==undefined&&this.status){
                this.internalActiveName='params'
            }
        })
        
    },
    methods: {
        handleClick(tab, event) {
            this.label = tab.label;
        }
    },
}
</script>

<style lang="scss" scoped>
::v-deep .adjust {
    position: absolute;
    top: 0;
    right: 0px;
    height: 418px;
    width: 450px;
    min-height: 300px;
    overflow-x: auto;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 6px 0px, rgba(0, 0, 0, 0.15) 0px 10px 20px 0px;
    border-radius: 5px;
    // padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: content-box;

    .el-tabs__content {
        #pane-params {
            max-height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    >.el-tabs__header {
        .el-tabs__item {
            padding: 0 15px !important;
        }
    }
}
</style>