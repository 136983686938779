import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const singleCell = [
    // // 单细胞路由
    {
        path: "Cell",
        name: "Cell",
        component: () => import("@/views/biologicalInformationProcess/singleCellAnalysis/Cell.vue"),
        meta: {
            title: "细胞",
            type: '单细胞分析'
        },
    },

    // 单细胞详情
    {
        path: "CellDetail",
        name: "CellDetail",
        component: () => import("@/views/biologicalInformationProcess/singleCellAnalysis/CellDetail"),
        meta: {
            title: "单细胞流程",
            type: '单细胞分析'
        },
    },
    {
        path: "/UMAP",
        name: "UMAP",
        component: () => import("@/views/biologicalInformationProcess/singleCellAnalysis/UMAP"),
        meta: {
            title: "细胞亚群对比展示图",
            type: '单细胞分析'
        },
    },
    {
        path: "/cellCycleAnalysis",
        name: "cellCycleAnalysis",
        component: () => import("@/views/biologicalInformationProcess/singleCellAnalysis/cellCycleAnalysis"),
        meta: {
            title: "细胞周期分析",
            type: '单细胞分析'
        },
    },
    // 细胞周期比例图
    {
        path: "/CellCycleDetail",
        name: "CellCycleDetail",
        component: () => import("@/views/biologicalInformationProcess/singleCellAnalysis/CellCycleDetail"),
        meta: {
            title: "细胞周期比例图",
        },
    },
    // 富集分析（GO）
    {
        path: "/EnrichmentGODetail",
        name: "EnrichmentGODetail",
        component: () => import("@/views/biologicalInformationProcess/singleCellAnalysis/EnrichmentGODetail"),
        meta: {
            title: "富集分析（GO）",
        },
    },
    {
        path: "/deconvolutionAnalysis",
        name: "deconvolutionAnalysis",
        component: () => import("@/views/biologicalInformationProcess/singleCellAnalysis/deconvolutionAnalysis"),
        meta: {
            title: "单细胞反卷积分析",
            type: '单细胞分析'
        },
    },
    // 富集分析（KEGG）
    {
        path: "/EnrichmentKEGGDetail",
        name: "EnrichmentKEGGDetail",
        component: () => import("@/views/biologicalInformationProcess/singleCellAnalysis/EnrichmentKEGGDetail"),
        meta: {
            title: "富集分析（KEGG）",
        },
    },
    {
        path: "/POfTranscriptionFactor",
        name: "POfTranscriptionFactor",
        component: () => import("@/views/biologicalInformationProcess/singleCellAnalysis/POfTranscriptionFactor"),
        meta: {
            title: "转录因子活性预测",
            type: '单细胞分析'
        },
    },
    {
        path: "/CAbundanceAOGExpressionP",
        name: "CAbundanceAOGExpressionP",
        component: () => import("@/views/biologicalInformationProcess/singleCellAnalysis/CAbundanceAOGExpressionP"),
        meta: {
            title: "细胞丰度与基因表达量的相关性热图",
            type: '单细胞分析'
        },
    },
    {
        path: "/SingleCellCNVAnalysis",
        name: "SingleCellCNVAnalysis",
        component: () => import("@/views/biologicalInformationProcess/singleCellAnalysis/SingleCellCNVAnalysis"),
        meta: {
            title: "单细胞CNV分析",
            type: '单细胞分析'
        },
    },
    {
        path: "/SingleCellCNVAnalysis_copykat",
        name: "SingleCellCNVAnalysis_copykat",
        component: () => import("@/views/biologicalInformationProcess/singleCellAnalysis/SingleCellCNVAnalysis_copykat"),
        meta: {
            title: "单细胞CNV分析（copykat",
            type: '单细胞分析'
        },
    },
    {
        path: "/CellCommunicationAnalysis_CellPhoneDB",
        name: "CellCommunicationAnalysis_CellPhoneDB",
        component: () => import("@/views/biologicalInformationProcess/singleCellAnalysis/CellCommunicationAnalysis_CellPhoneDB"),
        meta: {
            title: "细胞通讯分析（CellPhoneDB）",
            type: '单细胞分析'
        },
    },
    {
        path: "/AUCell",
        name: "AUCell",
        component: () => import("@/views/biologicalInformationProcess/singleCellAnalysis/AUCell"),
    },
    {
        path: "/singleUMAP",
        name: "singleUMAP",
        component: () => import("@/views/biologicalInformationProcess/singleCellAnalysis/singleUMAP"),
    },
    {
        path: "/scMayoMap",
        name: "scMayoMap",
        component: () => import("@/views/biologicalInformationProcess/singleCellAnalysis/scMayoMap"),
    },
    {
        path: "/singleCellTrajectoryAnalysis",
        name: "singleCellTrajectoryAnalysis",
        component: () => import("@/views/biologicalInformationProcess/singleCellAnalysis/singleCellTrajectoryAnalysis/index"),
    },
    // {
    //     path: "singleUMAP2",
    //     name: "singleUMAP2",
    //     component: () => import("@/views/biologicalInformationProcess/singleCellAnalysis/singleUMAP2"),
    // },
]

const WithTypes = singleCell.map(item => {
    if (!item.meta) {
        // 没有meta对象
        return {
            ...item,
            meta: {
                type: "单细胞分析",
            },
        };
    }

    // 有meta对象
    return {
        ...item,
        meta: {
            ...item.meta,
            type: "单细胞分析", // 替换成你想要的类型值
        },
    };
});

export default WithTypes 