<template>
  <div class="talk">
    <div
      v-if="convertedHTML !== ''"
      class="tecx"
      style="line-height: 2"
      v-html="convertedHTML"
    ></div>
    <span v-else style="line-height: 60px; width: 50%; color: #909399"
      >暂无说明</span
    >
    <div
      v-if="show"
      tabindex="-1"
      class="el-image-viewer__wrapper"
      style="z-index: 2005"
      @wheel="handleScroll"
    >
      <div class="el-image-viewer__mask" @click="close"></div>
      <span class="el-image-viewer__btn el-image-viewer__close" @click="close"
        ><i class="el-icon-close"></i> </span
      ><!---->
      <div class="el-image-viewer__btn el-image-viewer__actions">
        <div class="el-image-viewer__actions__inner">
          <i class="el-icon-zoom-out" @click="remove"></i>
          <i class="el-icon-zoom-in" @click="add"></i>
          <!-- <i class="el-image-viewer__actions__divider"></i> -->
          <i class="el-icon-refresh-left" @click="left"></i>
          <i class="el-icon-refresh-right" @click="right"></i>
        </div>
      </div>
      <div class="el-image-viewer__canvas">
        <img
          :src="currentSrc"
          class="el-image-viewer__img"
          :style="{
            width: width + '%',
            height: 'auto',
            transform: 'scale(1) rotate(' + deg + 'deg)',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/utils/bus";
export default {
  data() {
    return {
      description: null, //说明
      convertedHTML: "",
      pid: null,
      currentSrc: [],
      show: false,
      width: 20,
      deg: 0,
    };
  },
  // created() {
  //   this.pid = this.$route.query.pid;
  //   this.getChartData();
  // },
  mounted() {
    bus.$on("info", (data) => {
      // console.log(data);
      this.description = data[0].illustrate;
      let tempDiv = document.createElement("div");
      tempDiv.innerHTML = this.description;

      // 获取解码后的 HTML
      const convertedHTML = tempDiv.textContent || tempDiv.innerText;
// console.log(convertedHTML);
      // 在控制台输出解码后的 HTML

      this.convertedHTML = convertedHTML.replace(
        /<img/g,
        '<img class="images"'
      );
      this.$nextTick(() => {
        
        // console.log(document.querySelectorAll('.images'))
        const imglist = document.querySelectorAll(".images");
        imglist.forEach((item, index) => {
          item.style.cursor = 'pointer';
          item.addEventListener("click", (event) => {
            this.width = 40;
            this.deg = 0;
            this.currentSrc = [];
            this.currentSrc.push(event.target.currentSrc);
            this.show = true;
          });
        });
      });
    });
  },
  methods: {
    handleScroll(event) {
      // 判断滚轮方向，向上滚动增加width，向下滚动减小width
      if (event.deltaY < 0) {
        this.add();
      } else {
        this.remove();
      }
    },
    close() {
      this.show = false;
    },
    remove() {
      if (this.width > 20) {
        this.width = this.width - 5;
      }
    },
    add() {
      if (this.width < 70) {
        this.width = this.width + 5;
      }
    },
    left() {
      this.deg = this.deg - 90;
    },
    right() {
      this.deg = this.deg + 90;
    },
    // async getChartData() {
    //   let formData = new FormData();
    //   formData.append("pid", this.pid);
    //   const location_data = await this.$api.getDetailData(formData);
    //   // console.log(location_data);
    //   this.description = location_data[0].illustrate;
    //   let tempDiv = document.createElement("div");
    //   tempDiv.innerHTML = this.description;

    //   // 获取解码后的 HTML
    //   const convertedHTML = tempDiv.textContent || tempDiv.innerText;

    //   // 在控制台输出解码后的 HTML

    //   this.convertedHTML = convertedHTML.replace(
    //     /<img/g,
    //     '<img class="images"'
    //   );
    //   this.$nextTick(() => {
    //     // console.log(document.querySelectorAll('.images'))
    //     const imglist = document.querySelectorAll(".images");
    //     imglist.forEach((item, index) => {
    //       item.addEventListener("click", (event) => {
    //         // console.log(event);
    //         this.width = 40;
    //         this.deg = 0;
    //         this.currentSrc = [];
    //         this.currentSrc.push(event.target.currentSrc);
    //         this.show = true;
    //       });
    //     });
    //   });
    //   // console.log(this.convertedHTML);
    // },
  },
};
</script>

<style lang="scss" scoped>
.talk {
  .tecx {
    font-size: 14px !important;

    ::v-deep img {
      width: 100% !important;
      height: auto !important;
    }
  }

  .el-image-viewer__img {
    margin-left: -40px;
    margin-top: 0px;
    // max-height: 100%;
    // object-fit: scale-down;
    // max-width: 80%;
    min-width: 20%;
    transition: transform 0.3s ease 0s;
  }
}
</style>