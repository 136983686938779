/*!!
 *  Echarts 调整画布尺寸
 *
 *  Author:
 *  陈俊
 *
 *  2024 03 05
 *  使用方法：
 *		doResize(myChart); myChart是echarts对象(渲染后)
 */
; (function () {
	"use strict";

	function doResize(chart, obj) {
		// console.log('doresize init');
		let echart = false;
		if (chart.hasOwnProperty("_dom")) echart = true;
		let c = echart ? chart._dom : chart; //document.getElementById(chart._dom.id);
		if (c.getAttribute('hasResize')) return;
		c.setAttribute('hasResize', 'true');
		// 是否开启尺寸修改
		let resizeable = false
		// 鼠标按下时的坐标，并在修改尺寸时保存上一个鼠标的位置
		let clientX, clientY
		// div可修改的最小宽高
		let minW = 160, minH = 120
		// 鼠标按下时的位置，使用n、s、w、e表示
		let direc = ''
		let br = "3px dashed rgb(78 143 143)", ring = false;

		// 获取鼠标所在div的位置
		function getDirection(ev) {
			let xP, yP, offset, dir = '';
			xP = ev.offsetX;
			yP = ev.offsetY;
			offset = 20;

			if (yP < offset) dir += 'n';
			else if (yP > c.offsetHeight - offset) dir += 's';
			if (xP < offset) dir += 'w';
			else if (xP > c.offsetWidth - offset) dir += 'e';

			return dir;
		}

		// 双击事件发生时
		function dodbclick(e) {
			if (ring) {
				c.style.border = null;
				ring = false;
			} else {
				c.style.border = br;
				ring = true;
				 
			}
			if (echart) chart.resize();
		}
		c.removeEventListener('dblclick', dodbclick);
		c.addEventListener('dblclick', dodbclick);
		// dodbclick()
		// 添加鼠标滚轮事件监听器
		function dowheel(event) {
			if (!ring) return false;
			event.preventDefault(); // 防止默认行为
			var deltaY = event.deltaY || -event.detail || event.wheelDelta; // 判断不同浏览器支持的属性名称
			if (deltaY > 0) {
				// 向上滚动，增大DIV大小
				adjustSize(-10);
			} else {
				// 向下滚动，减小DIV大小
				adjustSize(10);
			}
		}
		c.removeEventListener('wheel', dowheel);
		c.addEventListener('wheel', dowheel);

		// body监听移动事件
		/*document.body.addEventListener('mousedown', function(e){
			c.style.border = null;
		  });*/

		// 鼠标移动事件
		function domousemove(e) {
			let cursor, d = getDirection(e);
			if (d === '') cursor = 'default';
			else cursor = d + '-resize';
			// 修改鼠标显示效果
			c.style.cursor = cursor;
			// 当开启尺寸修改时，鼠标移动会修改div尺寸
			if (resizeable) {
				// 鼠标按下的位置在右边，修改宽度
				if (direc.indexOf('e') !== -1) {
					c.style.width = Math.max(minW, c.offsetWidth + (e.clientX - clientX)) + 'px';
					clientX = e.clientX;
				}
				// 鼠标按下的位置在上部，修改高度
				if (direc.indexOf('n') !== -1) {
					c.style.height = Math.max(minH, c.offsetHeight + (clientY - e.clientY)) + 'px';
					clientY = e.clientY;
				}
				// 鼠标按下的位置在底部，修改高度
				if (direc.indexOf('s') !== -1) {
					c.style.height = Math.max(minH, c.offsetHeight + (e.clientY - clientY)) + 'px';
					clientY = e.clientY;
				}
				// 鼠标按下的位置在左边，修改宽度
				if (direc.indexOf('w') !== -1) {
					c.style.width = Math.max(minW, c.offsetWidth + (clientX - e.clientX)) + 'px';
					clientX = e.clientX;
				}
				//if(echart) chart.resize();
				//if(obj) eval(obj());
			}
		}
		document.body.removeEventListener('mousemove', domousemove);
		document.body.addEventListener('mousemove', domousemove);

		// 鼠标按下时开启尺寸修改
		function domousedown(e) {
			let d = getDirection(e)
			// 当位置为四个边和四个角时才开启尺寸修改
			if (d !== '') {
				resizeable = true;
				direc = d;
				clientX = e.clientX;
				clientY = e.clientY;
			}
		}
		c.removeEventListener('mousedown', domousedown);
		c.addEventListener('mousedown', domousedown);

		// 鼠标松开时结束尺寸修改
		function domouseup() {
			if (resizeable == true) {
				if (echart) chart.resize();
				if (obj) eval(obj());
			}
			resizeable = false;
		}
		document.body.removeEventListener('mouseup', domouseup);
		document.body.addEventListener('mouseup', domouseup);

		function adjustSize(amount) {
			let currentWidth = parseInt(getComputedStyle(c).width);
			let newWidth = Math.max(currentWidth + amount, minW); // 限制最小宽度为0
			let currentHeight = parseInt(getComputedStyle(c).height);
			//let newHeight = Math.max(currentHeight + amount, 0); // 限制最小高度为0
			let newHeight = Math.max(parseInt((newWidth / currentWidth) * currentHeight), minH); // 限制最小高度为0
			c.style.width = newWidth + 'px';
			c.style.height = newHeight + 'px';
			if (echart) chart.resize();
			if (obj) eval(obj());
		}

		/*window.onresize = function () {
			chart.resize();
		};*/

	}

	if (typeof window === "object") {
		window.doResize = doResize;
	}

	if (typeof module === "object" && typeof module.exports === "object") {
		module.exports = doResize;
	}

}());