(function () {
    let legendConfigs = null;

    function echartsLegendHelper(legendConfig, colors) {
        legendConfigs = legendConfig;

        const orient = legendConfig.orient || 'vertical';
        const legendItems = legendConfig.data || [];
        const defaultShape = legendConfig.icon || 'rect';
        const legendWidth = legendConfig.itemWidth || 15;
        const legendHeight = legendConfig.itemHeight || 15;
        const maxTextWidth = legendConfig.maxTextWidth || null;
        const columnWidth = legendWidth + 10 + maxTextWidth; // 图例宽度 + 间距 + 文本最大宽度
        const borderWidth = legendConfig.borderWidth || 0;
        const borderColor = legendConfig.borderColor || '#000';
        const itemGap = legendConfig.itemGap || 5;
        const title = legendConfig.title || ''; // 检测是否有标题
        const titleTextStyle = legendConfig.titleTextStyle || {}; // 标题的字体样式

        let currentColumn = 0;
        let currentHeight = 0;
        let currentRow = 0;
        let currentWidth = 0;
        const children = [];

        // 获取容器宽度和高度
        let containerWidth = window.innerWidth;
        let containerHeight = window.innerHeight;
        if (legendConfig.chart) {
            containerWidth = legendConfig.chart.getWidth();
            containerHeight = legendConfig.chart.getHeight();
        } else if (typeof legendConfig.containerConfig !== 'undefined') {
            containerWidth = typeof legendConfig.containerConfig.width === 'string' && legendConfig.containerConfig.width.endsWith('%')
                ? window.innerWidth * parseFloat(legendConfig.containerConfig.width) / 100
                : legendConfig.containerConfig.width;
            containerHeight = typeof legendConfig.containerConfig.height === 'string' && legendConfig.containerConfig.height.endsWith('%')
                ? window.innerHeight * parseFloat(legendConfig.containerConfig.height) / 100
                : legendConfig.containerConfig.height;
        }

        // 标题
        if (title) {
            children.push({
                type: 'text',
                z: 100,
                left: 0,
                top: -10,
                style: {
                    text: title,
                    fill: titleTextStyle.color || legendConfig.textStyle.color,
                    font: `${titleTextStyle.fontWeight || 'bold'} ${titleTextStyle.fontSize || 16}px ${titleTextStyle.fontFamily || 'Arial'}`,
                }
            });
            currentHeight += legendHeight + itemGap;
        }

        // 图例
        legendItems.forEach((item, index) => {
            const shape = defaultShape;
            const fill = colors[index] || legendConfig.textStyle.color;
            const itemWidth = legendWidth;
            const itemHeight = legendHeight;

            let shapeConfig;
            switch (shape) {
                case 'circle':
                    shapeConfig = {
                        type: 'circle',
                        z: 100,
                        shape: {
                            cx: itemWidth / 2,
                            cy: itemHeight / 2,
                            r: Math.min(itemWidth, itemHeight) / 2,
                        },
                        style: {
                            fill: fill,
                            stroke: borderColor,
                            lineWidth: borderWidth
                        }
                    };
                    break;
                case 'rect':
                    shapeConfig = {
                        type: 'rect',
                        z: 100,
                        shape: {
                            width: itemWidth,
                            height: itemHeight
                        },
                        style: {
                            fill: fill,
                            stroke: borderColor,
                            lineWidth: borderWidth
                        }
                    };
                    break;
                case 'roundRect':
                    shapeConfig = {
                        type: 'rect',
                        z: 100,
                        shape: {
                            x: 0,
                            y: 0,
                            width: itemWidth,
                            height: itemHeight,
                            r: [itemWidth / 4, itemWidth / 4, itemWidth / 4, itemWidth / 4]
                        },
                        style: {
                            fill: fill,
                            stroke: borderColor,
                            lineWidth: borderWidth
                        }
                    };
                    break;
                case 'triangle':
                    shapeConfig = {
                        type: 'polygon',
                        z: 100,
                        shape: {
                            points: [
                                [itemWidth / 2, 0],
                                [itemWidth, itemHeight],
                                [0, itemHeight]
                            ]
                        },
                        style: {
                            fill: fill,
                            stroke: borderColor,
                            lineWidth: borderWidth
                        }
                    };
                    break;
                case 'diamond':
                    shapeConfig = {
                        type: 'polygon',
                        z: 100,
                        shape: {
                            points: [
                                [itemWidth / 2, 0],
                                [itemWidth, itemHeight / 2],
                                [itemWidth / 2, itemHeight],
                                [0, itemHeight / 2]
                            ]
                        },
                        style: {
                            fill: fill,
                            stroke: borderColor,
                            lineWidth: borderWidth
                        }
                    };
                    break;
                case 'pin':
                    shapeConfig = {
                        type: 'path',
                        z: 100,
                        shape: {
                            pathData: 'M512 0c-282.8 0-512 229.2-512 512 0 102.6 32 206.6 91.6 294.4L512 1024l420.4-217.6c59.6-87.8 91.6-191.8 91.6-294.4C1024 229.2 794.8 0 512 0zM512 720c-114.7 0-208-93.3-208-208s93.3-208 208-208 208 93.3 208 208S626.7 720 512 720z',
                        },
                        style: {
                            fill: fill,
                            stroke: 'black',
                            lineWidth: 2
                        }
                    };
                    break;
                case 'arrow':
                    shapeConfig = {
                        type: 'polygon',
                        z: 100,
                        shape: {
                            points: [
                                [0, itemHeight / 2], // 左中
                                [itemWidth * 0.75, 0], // 右上
                                [itemWidth * 0.75, itemHeight / 3], // 右中上
                                [itemWidth, itemHeight / 3], // 尖端上
                                [itemWidth, (2 * itemHeight) / 3], // 尖端下
                                [itemWidth * 0.75, (2 * itemHeight) / 3], // 右中下
                                [itemWidth * 0.75, itemHeight], // 右下
                                [0, itemHeight / 2], // 左中
                            ]
                        },
                        style: {
                            fill: fill,
                            stroke: borderColor,
                            lineWidth: borderWidth
                        }
                    };
                    break;
                case 'line':
                    shapeConfig = {
                        type: 'line',
                        z: 100,
                        shape: {
                            x1: 0,
                            y1: itemHeight / 2,
                            x2: itemWidth,
                            y2: itemHeight / 2
                        },
                        style: {
                            stroke: fill,
                            lineWidth: 2
                        }
                    };
                    break;
                default:
                    shapeConfig = {
                        type: 'rect',
                        z: 100,
                        shape: {
                            width: itemWidth,
                            height: itemHeight
                        },
                        style: {
                            fill: fill,
                            stroke: borderColor,
                            lineWidth: borderWidth
                        }
                    };
            }

            if (orient === 'vertical') {
                if (currentHeight + itemHeight + 5 > containerHeight) {
                    currentColumn += 1;
                    currentHeight = title ? legendHeight + itemGap : 0; // 如果有标题，跳过标题高度
                }
                const left = currentColumn * columnWidth;
                const top = currentHeight;
                currentHeight += itemHeight + itemGap; // 每个图例项之间的间距

                children.push({
                    type: 'group',
                    left: left,
                    top: top,
                    children: [
                        shapeConfig,
                        {
                            type: 'text',
                            z: 100,
                            left: itemWidth + 5, // 图例项文本和矩形之间的间距
                            style: {
                                text: item,
                                fill: legendConfig.textStyle.color,
                                font: `${legendConfig.textStyle.fontWeight} ${legendConfig.textStyle.fontSize}px ${legendConfig.textStyle.fontFamily}`,
                                width: maxTextWidth, // 设置文本的最大宽度
                                overflow: 'truncate',
                                ellipsis: '...'
                            }
                        }
                    ]
                });
            } else if (orient === 'horizontal') {
                let textWidth = getTextWidth(item, legendConfig.textStyle); // 获取文本的实际宽度
                if (currentWidth + textWidth + legendWidth + 15 > containerWidth) { // 根据文本和图例项的宽度来判断是否换行
                    currentRow += 1;
                    currentWidth = 0;
                }

                const left = currentWidth;
                const top = currentRow * (legendHeight + itemGap + 5) + (title ? legendHeight + itemGap : 0); // 每个图例项之间的间距为itemGap+5
                currentWidth += textWidth + legendWidth + itemGap + 5; // 更新当前行宽度

                children.push({
                    type: 'group',
                    left: left,
                    top: top,
                    children: [
                        shapeConfig,
                        {
                            type: 'text',
                            z: 100,
                            left: legendWidth + 5, // 图例项文本和矩形之间的间距
                            style: {
                                text: item,
                                fill: legendConfig.textStyle.color,
                                font: `${legendConfig.textStyle.fontWeight} ${legendConfig.textStyle.fontSize}px ${legendConfig.textStyle.fontFamily}`
                            }
                        }
                    ]
                });
            }
        });

        return [
            {
                type: 'group',
                draggable: true,
                left: legendConfig.left,
                right: legendConfig.right,
                top: legendConfig.top,
                bottom: legendConfig.bottom,
                children: children
            }
        ];
    }

    function getTextWidth(text, textStyle) {
        const ctx = document.createElement('canvas').getContext('2d');
        ctx.font = `${textStyle.fontWeight} ${textStyle.fontSize}px ${textStyle.fontFamily}`;
        return ctx.measureText(text).width;
    }

    function echartsGrid(grid) {
        return {
            top: grid[0] + '%',
            bottom: grid[1] + '%',
            left: grid[2] + '%',
            right: grid[3] + '%',
        }
    }

    if (typeof window === "object") {
        window.echartsLegendHelper = echartsLegendHelper;
        window.echartsGrid = echartsGrid;
    }

    if (typeof module === "object" && typeof module.exports === "object") {
        module.exports = echartsLegendHelper;
        module.exports = echartsGrid;
    }
}());
