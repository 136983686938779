<template>
  <div>
    <div v-clickoutside="hideReplyBtn" @click="inputFocus" class="my-reply">
      <el-avatar class="header-img" :size="35" :src="myHeader"></el-avatar>
      <div class="reply-info">
        <div
          tabindex="0"
          contenteditable="true"
          id="replyInput"
          spellcheck="false"
          placeholder="输入评论..."
          class="reply-input"
          @focus="showReplyBtn"
          @input="onDivInput($event)"
        ></div>
      </div>
      <div class="reply-btn-box">
        <el-button
          v-show="btnShow"
          class="reply-btn"
          size="medium"
          @click="sendComment"
          type="primary"
          style="height: 100%"
          >发表评论</el-button
        >
      </div>
    </div>
    <span
      v-if="comments.length == 0"
      style="line-height: 60px; width: 50%; color: #909399"
      >暂无评论，快来发表吧</span
    >
    <template v-else>
      <div
        v-for="(item, i) in comments"
        :key="i"
        class="author-title reply-father"
      >
        <el-avatar
          class="header-img"
          :size="35"
          :src="item.authorAvatar"
        ></el-avatar>
        <!-- 作者，时间 -->

        <div class="author-info">
          <span class="author-name">{{ item.author }}</span>
          <span class="author-time">{{ item.ctime }}</span>
        </div>

        <div class="o">
          <!-- 评论内容 -->
          <div class="talk-box">
            <p
              class="reply"
              style="
                word-wrap: break-word;
                word-break: break-all;
                font-size: 14px;
              "
            >
              {{ item.content }}
            </p>
          </div>
          <!-- 评论，喜欢 -->
          <div class="icon-btn" style="display: flex; align-items: center">
            <span
              @click="showReplyInput(i, item.author, item.id)"
              style="
                display: flex;
                align-items: center;
                margin-right: 15px;
                font-size: 12px;
              "
              ><i>
                <svg
                  t="1703841903485"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="2325"
                  width="26"
                  height="32"
                >
                  <path
                    d="M517.85728 863.9488l-124.23168-144.62976H162.14016V229.00736h711.84384v490.31168H645.24288l-127.3856 144.62976zM203.10016 678.35904h209.32608l105.84064 123.1872 108.46208-123.1872h206.29504V269.96736H203.10016v408.39168z"
                    fill="#bfbfbf"
                    p-id="2326"
                  ></path>
                  <path
                    d="M293.10976 450.6624h72.97024v40.96h-72.97024zM481.5872 450.6624h72.97024v40.96h-72.97024zM670.06464 450.6624h72.97024v40.96h-72.97024z"
                    fill="#bfbfbf"
                    p-id="2327"
                  ></path></svg></i
              >{{ item.reply }}</span
            >
            <div class="beliked">
              <span
                @click="BeLike(item.id)"
                style="
                  display: flex;
                  align-items: center;
                  margin-right: 15px;
                  font-size: 12px;
                "
                v-if="item.liked"
              >
                <i
                  ><svg
                    t="1703842191632"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="4055"
                    width="26"
                    height="32"
                  >
                    <path
                      d="M669.781333 130.752c71.637333-11.093333 138.901333 11.477333 193.344 64.533333 55.317333 53.930667 81.834667 124.992 74.282667 199.530667-7.466667 73.642667-46.549333 146.368-112.32 210.474667-18.346667 17.898667-67.669333 66.218667-138.453333 135.637333-31.829333 31.232-65.706667 64.448-99.84 97.984L553.6 871.466667l-13.184 12.949333a40.554667 40.554667 0 0 1-56.832 0l-114.602667-112.64-24.213333-23.722667a677626.346667 677626.346667 0 0 0-145.856-142.762666C133.141333 541.184 94.08 468.48 86.613333 394.816c-7.552-74.538667 18.944-145.6 74.282667-199.530667 54.442667-53.056 121.706667-75.605333 193.344-64.533333 53.162667 8.213333 107.093333 34.688 157.781333 76.949333 50.709333-42.24 104.618667-68.736 157.781334-76.949333z"
                      fill="#d81e06"
                      p-id="4056"
                    ></path></svg></i
                >{{ item.likes }}</span
              >
              <span
                @click="BeLike(item.id)"
                style="
                  display: flex;
                  align-items: center;
                  margin-right: 15px;
                  font-size: 12px;
                "
                v-else
              >
                <i
                  ><svg
                    t="1705585788036"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="2340"
                    width="26"
                    height="26"
                  >
                    <path
                      d="M535.9 216.7l-22.9 23-22.9-23.1c-89.2-89.7-234.3-90.1-323.9-0.8l-0.8 0.8c-89.7 90.1-89.7 235.8 0 326L476 859.3c20.1 20.4 52.9 20.7 73.4 0.7l0.7-0.7 310.7-316.7c89.7-90.1 89.7-235.8 0-326-89.2-89.7-234.3-90.1-323.9-0.9l-1 1z"
                      p-id="2341"
                      fill="#cdcdcd"
                    ></path></svg></i
                >{{ item.likes }}</span
              >
            </div>

            <span
              @click="deleteData(item.id)"
              style="display: flex; align-items: center; font-size: 12px"
            >
              <i
                ><svg
                  t="1704627384493"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="1796"
                  width="26"
                  height="32"
                >
                  <path
                    d="M840 288H688v-56c0-40-32-72-72-72h-208C368 160 336 192 336 232V288h-152c-12.8 0-24 11.2-24 24s11.2 24 24 24h656c12.8 0 24-11.2 24-24s-11.2-24-24-24zM384 288v-56c0-12.8 11.2-24 24-24h208c12.8 0 24 11.2 24 24V288H384zM758.4 384c-12.8 0-24 11.2-24 24v363.2c0 24-19.2 44.8-44.8 44.8H332.8c-24 0-44.8-19.2-44.8-44.8V408c0-12.8-11.2-24-24-24s-24 11.2-24 24v363.2c0 51.2 41.6 92.8 92.8 92.8h358.4c51.2 0 92.8-41.6 92.8-92.8V408c-1.6-12.8-12.8-24-25.6-24z"
                    fill="#272636"
                    p-id="1797"
                  ></path>
                  <path
                    d="M444.8 744v-336c0-12.8-11.2-24-24-24s-24 11.2-24 24v336c0 12.8 11.2 24 24 24s24-11.2 24-24zM627.2 744v-336c0-12.8-11.2-24-24-24s-24 11.2-24 24v336c0 12.8 11.2 24 24 24s24-11.2 24-24z"
                    fill="#272636"
                    p-id="1798"
                  ></path></svg></i
            ></span>
          </div>
        </div>

        <!-- 回复内容 -->
        <div class="reply-box">
          <div v-for="(reply, j) in item.replies" :key="j" class="author-title">
            <el-avatar
              class="header-img"
              :size="35"
              :src="reply.authorAvatar"
            ></el-avatar>
            <div class="author-info">
              <span class="author-name">{{ reply.author }}</span>
              <span class="author-time">{{ reply.ctime }}</span>
            </div>
            <div class="o">
              <div class="talk-box">
                <!-- 注意 -->
                <p style="font-size: 13px">
                  <!-- 回复的谁 -->
                  <span style="margin-right: 5px">回复 {{ item.author }}:</span>
                  <span class="reply" style="font-size: 14px">{{
                    reply.content
                  }}</span>
                </p>
              </div>
              <div
                class="icon-btn"
                style="display: flex; align-items: center; font-size: 12px"
              >
                <span
                  @click="showReplyInput(i, reply.author, reply.id)"
                  style="
                    display: flex;
                    align-items: center;
                    margin-right: 15px;
                    font-size: 12px;
                  "
                  ><i>
                    <svg
                      t="1703841903485"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="2325"
                      width="26"
                      height="32"
                    >
                      <path
                        d="M517.85728 863.9488l-124.23168-144.62976H162.14016V229.00736h711.84384v490.31168H645.24288l-127.3856 144.62976zM203.10016 678.35904h209.32608l105.84064 123.1872 108.46208-123.1872h206.29504V269.96736H203.10016v408.39168z"
                        fill="#bfbfbf"
                        p-id="2326"
                      ></path>
                      <path
                        d="M293.10976 450.6624h72.97024v40.96h-72.97024zM481.5872 450.6624h72.97024v40.96h-72.97024zM670.06464 450.6624h72.97024v40.96h-72.97024z"
                        fill="#bfbfbf"
                        p-id="2327"
                      ></path></svg></i
                  >{{ reply.reply }}</span
                >
                <div
                  v-if="reply.reply > 0 && reply.replies == null"
                  @click="moreReply(i, j, reply.id, reply.mid, reply)"
                  class="zhan"
                  style="display: flex; align-items: center; font-size: 13px"
                >
                  <svg
                    t="1704701868520"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="3940"
                    width="26"
                    height="26"
                  >
                    <path
                      d="M490.666667 601.6L797.866667 298.666667l59.733333 59.733333-302.933333 302.933333-59.733334 64-59.733333-59.733333L128 358.4 187.733333 298.666667l302.933334 302.933333z"
                      fill="#444444"
                      p-id="3941"
                    ></path>
                  </svg>
                  <span style="width: 34px">展开</span>
                </div>
                <div class="beliked">
                  <span
                    v-if="reply.liked"
                    @click="BeLike2(reply.id)"
                    style="
                      display: flex;
                      align-items: center;
                      margin-right: 15px;
                      font-size: 12px;
                    "
                  >
                    <i>
                      <svg
                        t="1703842191632"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="4055"
                        width="26"
                        height="32"
                      >
                        <path
                          d="M669.781333 130.752c71.637333-11.093333 138.901333 11.477333 193.344 64.533333 55.317333 53.930667 81.834667 124.992 74.282667 199.530667-7.466667 73.642667-46.549333 146.368-112.32 210.474667-18.346667 17.898667-67.669333 66.218667-138.453333 135.637333-31.829333 31.232-65.706667 64.448-99.84 97.984L553.6 871.466667l-13.184 12.949333a40.554667 40.554667 0 0 1-56.832 0l-114.602667-112.64-24.213333-23.722667a677626.346667 677626.346667 0 0 0-145.856-142.762666C133.141333 541.184 94.08 468.48 86.613333 394.816c-7.552-74.538667 18.944-145.6 74.282667-199.530667 54.442667-53.056 121.706667-75.605333 193.344-64.533333 53.162667 8.213333 107.093333 34.688 157.781333 76.949333 50.709333-42.24 104.618667-68.736 157.781334-76.949333z"
                          fill="#d81e06"
                          p-id="4056"
                        ></path></svg></i
                    >{{ reply.likes }}
                  </span>

                  <span
                    v-else
                    @click="BeLike2(reply.id)"
                    style="
                      display: flex;
                      align-items: center;
                      margin-right: 15px;
                      font-size: 12px;
                    "
                  >
                    <i>
                      <svg
                        t="1705585788036"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="2340"
                        width="26"
                        height="26"
                      >
                        <path
                          d="M535.9 216.7l-22.9 23-22.9-23.1c-89.2-89.7-234.3-90.1-323.9-0.8l-0.8 0.8c-89.7 90.1-89.7 235.8 0 326L476 859.3c20.1 20.4 52.9 20.7 73.4 0.7l0.7-0.7 310.7-316.7c89.7-90.1 89.7-235.8 0-326-89.2-89.7-234.3-90.1-323.9-0.9l-1 1z"
                          p-id="2341"
                          fill="#cdcdcd"
                        ></path></svg></i
                    >{{ reply.likes }}
                  </span>
                </div>

                <span
                  @click="deleteData2(reply.id)"
                  style="display: flex; align-items: center"
                >
                  <i
                    ><svg
                      t="1704627384493"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="1796"
                      width="26"
                      height="32"
                    >
                      <path
                        d="M840 288H688v-56c0-40-32-72-72-72h-208C368 160 336 192 336 232V288h-152c-12.8 0-24 11.2-24 24s11.2 24 24 24h656c12.8 0 24-11.2 24-24s-11.2-24-24-24zM384 288v-56c0-12.8 11.2-24 24-24h208c12.8 0 24 11.2 24 24V288H384zM758.4 384c-12.8 0-24 11.2-24 24v363.2c0 24-19.2 44.8-44.8 44.8H332.8c-24 0-44.8-19.2-44.8-44.8V408c0-12.8-11.2-24-24-24s-24 11.2-24 24v363.2c0 51.2 41.6 92.8 92.8 92.8h358.4c51.2 0 92.8-41.6 92.8-92.8V408c-1.6-12.8-12.8-24-25.6-24z"
                        fill="#272636"
                        p-id="1797"
                      ></path>
                      <path
                        d="M444.8 744v-336c0-12.8-11.2-24-24-24s-24 11.2-24 24v336c0 12.8 11.2 24 24 24s24-11.2 24-24zM627.2 744v-336c0-12.8-11.2-24-24-24s-24 11.2-24 24v336c0 12.8 11.2 24 24 24s24-11.2 24-24z"
                        fill="#272636"
                        p-id="1798"
                      ></path></svg></i
                ></span>
              </div>
            </div>
            <div class="reply-box"></div>
          </div>
        </div>
        <div v-show="_inputShow(i)" class="my-reply my-comment-reply">
          <el-avatar class="header-img" :size="35" :src="myHeader"></el-avatar>
          <div class="reply-info">
            <div
              tabindex="0"
              contenteditable="true"
              spellcheck="false"
              placeholder="输入评论..."
              @input="onDivInput($event)"
              class="reply-input reply-comment-input"
            ></div>
          </div>
          <div class="reply-btn-box">
            <el-button
              class="reply-btn"
              size="medium"
              @click="sendCommentReply(i)"
              type="primary"
              >发表评论</el-button
            >
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import bus from "../../utils/bus";
const clickoutside = {
  // 初始化指令
  bind(el, binding, vnode) {
    function documentHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false;
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e);
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.vueClickOutside = documentHandler;
    document.addEventListener("click", documentHandler);
  },
  update() {},
  unbind(el, binding) {
    // 解除事件监听
    document.removeEventListener("click", el.vueClickOutside);
    delete el.vueClickOutside;
  },
};
export default {
  name: "ArticleComment",
  data() {
    return {
      // 是否被喜欢的表示
      isLiked: false,
      // zhankai
      // zhanStatus: true,
      btnShow: false,
      replyInput: null, // 将 replyInput 定义为数据属性
      latestCommentIndex: -1, // 用于跟踪最新添加的评论的索引
      // 被喜欢的，呗点赞的
      beLiked: false,
      index: "0",
      replyComment: "",
      myName: "",
      myHeader: "",
      myId: 19870621,
      to: "",
      toId: -1,
      comments: [
        {
          name: "Lana Del Rey",
          id: 19870621,
          headImg:
            "https://ae01.alicdn.com/kf/Hd60a3f7c06fd47ae85624badd32ce54dv.jpg",
          comment: "我发布一张新专辑Norman Fucking Rockwell,大家快来听啊",
          time: "2019年9月16日 18:43",
          commentNum: 2,
          like: 15,
          inputShow: false,
          reply: [
            {
              from: "Taylor Swift",
              fromId: 19891221,
              fromHeadImg:
                "https://ae01.alicdn.com/kf/H94c78935ffa64e7e977544d19ecebf06L.jpg",
              to: "Lana Del Rey",
              toId: 19870621,
              comment: "我很喜欢你的新专辑！！",
              time: "2019年9月16日 18:43",
              commentNum: 1,
              like: 15,
              inputShow: false,
            },
            {
              from: "Ariana Grande",
              fromId: 1123,
              fromHeadImg:
                "https://ae01.alicdn.com/kf/Hf6c0b4a7428b4edf866a9fbab75568e6U.jpg",
              to: "Lana Del Rey",
              toId: 19870621,
              comment: "别忘记宣传我们的合作单曲啊",
              time: "2019年9月16日 18:43",
              commentNum: 0,
              like: 5,
              inputShow: false,
            },
          ],
        },
        {
          name: "Taylor Swift",
          id: 19891221,
          headImg:
            "https://ae01.alicdn.com/kf/H94c78935ffa64e7e977544d19ecebf06L.jpg",
          comment: "我发行了我的新专辑Lover",
          time: "2019年9月16日 18:43",
          commentNum: 1,
          like: 5,
          inputShow: false,
          reply: [
            {
              from: "Lana Del Rey",
              fromId: 19870621,
              fromHeadImg:
                "https://ae01.alicdn.com/kf/Hd60a3f7c06fd47ae85624badd32ce54dv.jpg",
              to: "Taylor Swift",
              toId: 19891221,
              comment: "新专辑和speak now 一样棒！",
              time: "2019年9月16日 18:43",
              commentNum: 25,
              like: 5,
              inputShow: false,
            },
          ],
        },
        {
          name: "Norman Fucking Rockwell",
          id: 20190830,
          headImg:
            "https://ae01.alicdn.com/kf/Hdd856ae4c81545d2b51fa0c209f7aa28Z.jpg",
          comment: "Plz buy Norman Fucking Rockwell on everywhere",
          time: "2019年9月16日 18:43",
          commentNum: 0,
          like: 5,
          inputShow: false,
          reply: [],
        },
      ],
      comments: [],
    };
  },
  directives: { clickoutside },
  created() {
    this.pid = this.$route.query.pid;
    this.TalkData(this.pid);
    bus.$on("Send_Vip_Name", (data) => {
      // console.log(data);
      this.myName = data;
      // this.VIP_Num = data;
      // console.log(this.VIP_Num);
    });
    bus.$on("Send_Vip_Data", (data) => {
      // console.log(data);
      this.myHeader = data.user.headimgurl;
      // console.log(this.myHeader);
      // this.VIP_Num = data;
      // console.log(this.VIP_Num);
    });
  },
  watch: {
    comments: {
      handler(newValue) {
        // console.log(newValue);
      },
      deep: true,
    },
  },
  methods: {
    // 获取评论列表
    // 通过pid请求接口获得用户上传的数据
    async TalkData(pid) {
      let formData = new FormData();
      formData.append("pid", pid);
      const Talk_data = await this.$api.getTalkData(formData);
      // console.log(Talk_data);
      if (Talk_data) {
        this.comments = Talk_data.data.data;
      }
      // this.data = data.data.res.data;
    },
    // 获取评论的评论
    async moreReply(i, j, id, mid, reply) {
      console.log(i, j, id, mid, reply);
      let formData = new FormData();
      formData.append("mid", mid);
      const reTalk_data = await this.$api.getTalkData(formData);
      console.log(reTalk_data);
      // this.comments[i].replies =(reTalk_data.data.data[0].replies)
      // this.comments = this.comments[i].replies.push()

      // this.comments[i].replies = []

      const isObjectInArray = this.comments[i].replies.some(
        (obj) => obj.id === reTalk_data.data.data[0].replies[0].id
      );
      // filteredArray.map(item =>{
      //   this.comments[i].replies.push(item)
      // })
      // 如果不存在相同ID的对象，则将新对象推送到数组中
      if (!isObjectInArray) {
        this.comments[i].replies.push(reTalk_data.data.data[0].replies[0]);
      }
      console.log(this.comments, isObjectInArray, this.comments[i].replies);
    },
    // 发表评论
    async setTalkData(pid, replyComment) {
      let formData = new FormData();
      formData.append("action", "add");
      formData.append("pid", pid);
      formData.append("content", replyComment);
      const send_Talk_data = await this.$api.getTalkData(formData);
      // console.log(send_Talk_data);
      // let timeNow = new Date().getTime()
      // if (this.comments.length>0) {
      //   var IDNum = this.comments.length+999999
      // }else{
      //   var IDNum = 1
      // }
      this.pid = this.$route.query.pid;
      this.TalkData(this.pid);
      // if (send_Talk_data.data == 'ok') {
      //         //  this.comments.map((item, index) => {
      //         // console.log(item, index);
      //         this.comments.push({
      //           'author':this.myName,
      //           'authorAvatar':this.myHeader,
      //           'content':this.replyComment,
      //           'ctime':timeNow,
      //           'id':IDNum,
      //           'liked':0,
      //           'likes':0,
      //           'pid':this.pid,
      //           'replies':[],
      //           'reply':0,
      //           'uid':30
      //         })
      //       //  item.replies.map((list,i1)=>{
      //       //   console.log(list,i1);
      //       //   item.replies = item.replies.filter(obj=>obj.id != id)
      //       //   item.reply -= 1
      //       //  })
      //       // });
      //       console.log(this.comments);
      // }
    },
    // 回复评论
    async ReplyTalkData(mid, replyComment) {
      let formData = new FormData();
      formData.append("action", "add");
      formData.append("mid", mid);
      formData.append("content", replyComment);
      const send_Talk_data = await this.$api.getTalkData(formData);
      // console.log(send_Talk_data);
      this.pid = this.$route.query.pid;
      this.TalkData(this.pid);
    },
    // 删除评论
    deleteData(id) {
      // console.log(id);
      let formData = new FormData();
      formData.append("action", "delete");
      formData.append("id", id);
      this.axios({
        url: "https://cnsknowall.com/api/message", // 设置请求的地址
        method: "POST", // 设置请求方法
        data: {
          // get请求使用params进行参数凭借,如果是post请求用data
          action: "delete",
          id: id,
        },
      })
        .then((response) => {
          // console.log(response.data); //后台返回的数据
          if (response.data.data[0] == "ok") {
            // 继续
            this.comments.map((item, index) => {
              // console.log(item, index);
              this.comments = this.comments.filter((obj) => obj.id != id);
              // console.log(this.comments.filter(obj => obj[id] != id));
            });
          }

          this.$message({
            showClose: true,
            message: "删除成功成功",
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error.response.data);
          this.$message({
            showClose: true,
            message: "错误失败",
            type: "error",
          });
        });
      // const delete_Talk_data = this.$api.getTalkData(formData);
    },

    // 删除回复评论
    deleteData2(id) {
      // console.log(id);
      let formData = new FormData();
      formData.append("action", "delete");
      formData.append("id", id);
      this.axios({
        url: "https://cnsknowall.com/api/message", // 设置请求的地址
        method: "POST", // 设置请求方法
        data: {
          // get请求使用params进行参数凭借,如果是post请求用data
          action: "delete",
          id: id,
        },
      })
        .then((response) => {
          // console.log(response.data); //后台返回的数据
          if (response.data.data[0] == "ok") {
            // 继续
            this.comments.map((item, index) => {
              console.log(item, index);
              item.replies.map((list, i1) => {
                console.log(list, i1);
                item.replies = item.replies.filter((obj) => obj.id != id);
                item.reply -= 1;
              });
              console.log(item.replies);
            });
          }

          this.$message({
            showClose: true,
            message: "删除成功成功",
            type: "success",
          });
        })
        .catch((error) => {
          // console.log(error.response.data);
          this.$message({
            showClose: true,
            message: "错误失败",
            type: "error",
          });
        });
      // const delete_Talk_data = this.$api.getTalkData(formData);
    },
    // 点赞
    BeLike(id) {
      // console.log(id);

      let formData = new FormData();
      // formData.append("action", "like");
      // formData.append("id", id);
      this.axios({
        url: "https://cnsknowall.com/api/message", // 设置请求的地址
        method: "POST", // 设置请求方法
        data: {
          // get请求使用params进行参数凭借,如果是post请求用data
          action: "like",
          id: id,
        },
      })
        .then((response) => {
          // console.log(response.data); //后台返回的数据

          if (response.data.data[0]) {
            this.comments.map((item, index) => {
              // console.log(item, index);
              if (item.id == id) {
                item.likes += 1;
                item.liked = 1;
              }
            });

            // this.pid = this.$route.query.pid;
            // this.TalkData(this.pid);
            this.$message({
              showClose: true,
              message: "成功点赞",
              type: "success",
            });
          } else {
            // this.pid = this.$route.query.pid;
            // this.TalkData(this.pid);
            this.comments.map((item, index) => {
              // console.log(item, index);
              if (item.id == id) {
                item.likes -= 1;
                item.liked = 0;
              }
            });
            this.$message({
              showClose: true,
              message: "取消点赞",
              type: "warning",
            });
          }
        })
        .catch((error) => {
          // console.log(error.response.data);
          this.$message({
            showClose: true,
            message: "错误",
            type: "error",
          });
        });
      // const delete_Talk_data = this.$api.getTalkData(formData);
      // this.pid = this.$route.query.pid;
      // this.$nextTick(() => {
      //   this.TalkData(this.pid);
      // });
    },
    // 回复点赞
    BeLike2(id) {
      // console.log(id);

      let formData = new FormData();
      // formData.append("action", "like");
      // formData.append("id", id);
      this.axios({
        url: "https://cnsknowall.com/api/message", // 设置请求的地址
        method: "POST", // 设置请求方法
        data: {
          // get请求使用params进行参数凭借,如果是post请求用data
          action: "like",
          id: id,
        },
      })
        .then((response) => {
          // console.log(response.data); //后台返回的数据

          if (response.data.data[0]) {
            this.comments.map((item, index) => {
              // console.log(item, index);
              item.replies.map((list, i) => {
                // console.log(list, i);
                if (list.id == id) {
                  list.likes += 1;
                  list.liked = 1;
                }
              });
            });

            // this.pid = this.$route.query.pid;
            // this.TalkData(this.pid);
            this.$message({
              showClose: true,
              message: "成功点赞",
              type: "success",
            });
          } else {
            // this.pid = this.$route.query.pid;
            // this.TalkData(this.pid);
            this.comments.map((item, index) => {
              // console.log(item, index);
              item.replies.map((list, i) => {
                if (list.id == id) {
                  list.likes -= 1;
                  list.liked = 0;
                }
              });
            });
            this.$message({
              showClose: true,
              message: "取消点赞",
              type: "warning",
            });
          }
        })
        .catch((error) => {
          // console.log(error.response.data);
          this.$message({
            showClose: true,
            message: "错误",
            type: "error",
          });
        });
    },
    //
    inputFocus() {
      this.replyInput = document.getElementById("replyInput");
      this.replyInput.style.padding = "8px 8px";
      this.replyInput.style.border = "2px solid blue";
      this.replyInput.focus();
    },
    showReplyBtn() {
      this.btnShow = true;
    },
    hideReplyBtn() {
      this.btnShow = false;
      replyInput.style.padding = "10px";
      replyInput.style.border = "none";
    },
    showReplyInput(i, name, id) {
      // console.log(i, name, id);
      this.btnShow = true;
      // console.log(this.btnShow);
      this.comments[this.index].inputShow = false;
      // console.log(this.comments[this.index].inputShow );
      this.index = i;
      this.comments[i].inputShow = true;
      this.to = name;
      this.toId = id;
    },
    _inputShow(i) {
      return this.comments[i].inputShow;
    },
    sendComment() {
      if (!this.replyComment) {
        this.$message({
          showClose: true,
          type: "warning",
          message: "评论不能为空",
        });
      } else {
        // console.log(this.replyComment);
        this.setTalkData(this.pid, this.replyComment);
      }
    },
    sendCommentReply(i) {
      // console.log(i);
      if (!this.replyComment) {
        this.$message({
          showClose: true,
          type: "warning",
          message: "评论不能为空",
        });
      } else {
        let a = {};
        let timeNow = new Date().getTime();
        let time = this.dateStr(timeNow);

        this.ReplyTalkData(this.toId, this.replyComment);
      }
    },
    onDivInput: function (e) {
      this.replyComment = e.target.innerText;
      // console.log(this.replyComment);
    },
    dateStr(date) {
      //获取js 时间戳
      var time = new Date().getTime();
      //去掉 js 时间戳后三位，与php 时间戳保持一致
      time = parseInt((time - date) / 1000);
      //存储转换值
      var s;
      if (time < 60 * 10) {
        //十分钟内
        return "刚刚";
      } else if (time < 60 * 60 && time >= 60 * 10) {
        //超过十分钟少于1小时
        s = Math.floor(time / 60);
        return s + "分钟前";
      } else if (time < 60 * 60 * 24 && time >= 60 * 60) {
        //超过1小时少于24小时
        s = Math.floor(time / 60 / 60);
        return s + "小时前";
      } else if (time < 60 * 60 * 24 * 30 && time >= 60 * 60 * 24) {
        //超过1天少于30天内
        s = Math.floor(time / 60 / 60 / 24);
        return s + "天前";
      } else {
        //超过30天ddd
        var date = new Date(parseInt(date));
        return (
          date.getFullYear() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getDate()
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.my-reply {
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  background-color: #fafbfc;

  .header-img {
    display: inline-block;
    vertical-align: top;
  }

  .reply-info {
    display: inline-block;
    margin-left: 5px;
    width: 70%;

    @media screen and (max-width: 1200px) {
      width: 80%;
    }

    .reply-input {
      min-height: 20px;
      line-height: 22px;
      padding: 10px 10px;
      color: #ccc;
      border: 1px solid #ccc !important;
      background-color: #fff;
      border-radius: 5px;

      &:empty:before {
        content: attr(placeholder);
      }

      &:focus:before {
        content: none;
      }

      &:focus {
        padding: 8px 8px;
        border: 2px solid blue;
        box-shadow: none;
        outline: none;
      }
    }
  }

  .reply-btn-box {
    // height: 25px;
    margin: 10px 0;

    .reply-btn {
      height: 100%;
      position: relative;
      float: right;
      // margin-right: 15px;
      margin-left: 5px;
    }
  }
}

.my-comment-reply {
  margin-left: 50px;

  .reply-input {
    width: flex;
  }
}

.author-title:not(:last-child) {
  border-bottom: 1px solid rgba(178, 186, 194, 0.3);
}

.author-title {
  padding: 10px;

  .header-img {
    display: inline-block;
    vertical-align: top;
  }

  .author-info {
    display: inline-block;
    margin-left: 5px;
    width: 60%;
    height: 40px;
    line-height: 20px;

    > span {
      display: block;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .author-name {
      color: #000;
      font-size: 14px;
      font-weight: bold;
    }

    .author-time {
      font-size: 11px;
    }
  }

  .icon-btn {
    width: 30%;
    padding: 0 !important;
    // float: right;
    margin-left: 44px;

    @media screen and (max-width: 1200px) {
      width: 20%;
      padding: 7px;
    }

    > span {
      cursor: pointer;
    }

    .iconfont {
      margin: 0 5px;
    }
  }

  .talk-box {
    margin: 0px 30px 0 50px;
    line-height: 30px;

    > p {
      margin: 0;
    }

    .reply {
      font-size: 16px;
      color: #000;
    }
  }

  .reply-box {
    margin: 10px 0 0 50px;
    background-color: #efefef;
  }
}

::v-deep .el-button--medium {
  padding: 10px 0;
}
</style>
