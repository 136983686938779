/*!!
 *  Echarts Svg Format
 *
 *  Author:
 *  陈俊
 *
 *  2023 07 12
 */

;(function () {
    "use strict";
    
	var DEFAULT_FONT_FAMILY = 'msyh';
	var REG = new RegExp("[\\u4E00-\\u9FFF]+", "g");
	
	function fontFamily(font, text){
		if(!font) return DEFAULT_FONT_FAMILY;
		if(REG.test(text)) return DEFAULT_FONT_FAMILY;
		if(REG.test(font)) return DEFAULT_FONT_FAMILY;
		return font;
	}
	
	function hsl2rgb(h, s, l) {
		var C, hh, r, g, b, X, m;
		if(h == "") h = 0;
		if(s == "") s = 0;
		if(l == "") l = 0;
		h = parseFloat(h);
		s = parseFloat(s);
		l = parseFloat(l);
		if(h < 0) h = 0;
		if(s < 0) s = 0;
		if(l < 0) l = 0;
		if(h >= 360) h = 359;
		if(s > 100) s = 100;
		if(l > 100) l = 100;
		s /= 100;
		l /= 100;
		C = (1 - Math.abs(2 * l - 1)) * s;
		hh = h / 60;
		X = C * (1 - Math.abs(hh % 2 - 1));
		r = g = b = 0;
		if(hh >= 0 && hh < 1) {
			r = C;
			g = X;
		} else if(hh >= 1 && hh < 2) {
			r = X;
			g = C;
		} else if(hh >= 2 && hh < 3) {
			g = C;
			b = X;
		} else if(hh >= 3 && hh < 4) {
			g = X;
			b = C;
		} else if(hh >= 4 && hh < 5) {
			r = X;
			b = C;
		} else {
			r = C;
			b = X;
		}
		m = l - C / 2;
		r += m;
		g += m;
		b += m;
		r *= 255;
		g *= 255;
		b *= 255;
		r = Math.floor(r);
		g = Math.floor(g);
		b = Math.floor(b);
		return [r, g, b];
	}

    function svgformat(){
		var fills = document.querySelectorAll("svg path[fill]");
		if(fills.length){
			fills.forEach((el) => {
				var hsl = el.getAttribute('fill'), rgb = '';
				if(hsl.substring(0,4) == 'hsl('){
					hsl = hsl.replace('hsl(', '').replaceAll('%', '').replace(')', '');
					rgb = eval('hsl2rgb(' + hsl + ')');
					rgb = 'rgb('+ rgb[0] +',' + rgb[1] + ',' + rgb[2] + ')';
					//console.log(hsl, rgb);
					el.setAttribute('fill', rgb);
				}
			});
		}
		
		var ts = document.querySelectorAll("svg text");
		if(ts.length){
			ts.forEach((el) => {
				el.setAttribute("stroke", "");
				var t = el.innerHTML;
				if(REG.test(t)){
					//console.log(t, el.style['font-family']);
					el.style['font-family'] = DEFAULT_FONT_FAMILY;
				}
			});
		}

		var ts = document.querySelectorAll("svg path[d='']");
		if(ts.length){
			ts.forEach((el) => {
				el.setAttribute("d", "M 0 0");
			});
		}

	}
	
	
	
	
	if (typeof window === "object") {
		window.svgformat = svgformat;
	}
	
	if (typeof module === "object" && typeof module.exports === "object") {
		module.exports = svgformat;
	}
	
}());