<template>
    <div class="total" style="position: relative; display: inline-block">
        <el-dropdown trigger="click">
            <el-tooltip placement="right" effect="dark">
                <div slot="content" style="font-size: 14px; width: 300px">
                    ①出图后双击图片会出现虚线外框，可通过拉伸虚线外框调节图表长宽比例；同时可通过鼠标滑轮调节图片大小；点击图例可拖动图例位置。<br />②导出矢量PDF后可通过Adobe
                    Acrobat Pro D（PDF编辑器）软件自定义编辑全图的标题、坐标轴、
                    图例和标签的字体大小、位置、添加/删除横线等细节的精细调整。
                </div>
                <el-button type="primary" trigger="click" class="dow">
                    下载图片<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
            </el-tooltip>
            <el-dropdown-menu class="download-buttons">
                <div class="top" style="display: flex; align-items: center">
                    <span @mouseover="showDiv2" @mouseout="hideDiv2">
                        <el-dropdown-item>
                            <el-button class="item" type="primary" @click="downloadChart('png')">
                                Download PNG
                            </el-button>
                        </el-dropdown-item>
                        <el-radio-group v-model="radio" v-show="showSecondDiv" style="
                position: absolute;
                top: 0;
                left: 177px;
                padding: 10px;
                background: #fff;
                border-radius: 10px;
              ">
                            <el-radio :label="2" style="padding: 5px 0">300 dpi</el-radio>
                            <el-radio :label="3" style="padding: 5px 0">600 dpi</el-radio>
                            <el-radio :label="5" style="padding: 5px 0">900 dpi</el-radio>
                        </el-radio-group>
                    </span>
                </div>
                <div v-if="!is3D" class="top" style="display: flex; align-items: center">
                    <span @mouseover="showDiv3" @mouseout="hideDiv3">
                        <el-dropdown-item @click="downloadChart('tiff')">
                            <form id="pdfForm1" action="https://cnsknowall.com/api/image2tiff" method="post">
                                <input type="hidden" id="paramInput1" name="data" value="" />
                                <input type="hidden" id="paramInput12" name="dpi" value="" />
                                <el-button class="item" type="primary" style="margin: 10px 0"
                                    @click="downloadChart('tiff')">Download TIFF</el-button>
                            </form>
                        </el-dropdown-item>
                        <el-radio-group v-model="radio1" v-show="showSecondDiv1" style="
                position: absolute;
                top: 35px;
                left: 177px;
                padding: 10px;
                background: #fff;
                border-radius: 10px;
              ">
                            <el-radio :label="300" style="padding: 5px 0">300 dpi</el-radio>
                            <el-radio :label="600" style="padding: 5px 0">600 dpi</el-radio>
                            <el-radio :label="900" style="padding: 5px 0">900 dpi</el-radio>
                        </el-radio-group>
                    </span>
                </div>
                <div v-if="!is3D" class="top" style="display: flex; align-items: center">
                    <el-dropdown-item @click="downloadChart('pdf')">
                        <form id="pdfForm" action="https://cnsknowall.com/api/html2pdf" method="post">
                            <!-- 添加一个隐藏的input元素用于存放参数 -->
                            <input type="hidden" id="paramInput" name="data" value="" />

                            <!-- 其他的表单元素可以放在这里，如果需要的话 -->

                            <!-- 提交按钮 -->
                            <!-- <input type="button" value="生成PDF" @click="downloadChart('pdf')" /> -->
                            <el-button class="item" type="primary" @click="downloadChart('pdf')"
                                style="margin-bottom: 10px">Download PDF</el-button>
                        </form>
                        <!-- <el-button
          class="item"
          type="primary"
          @click="downloadChart('pdf')"
          style="margin: 10px 0"
          >Download PDF</el-button
        > -->
                    </el-dropdown-item>
                    <!-- <el-radio v-model="radio2" label="300">300 dpi</el-radio>
          <el-radio v-model="radio2" label="600">600 dpi</el-radio>
          <el-radio v-model="radio2" label="900" style="margin-right: 20px"
            >900 dpi</el-radio
          > -->
                </div>
                <div v-if="!is3D" class="top" style="display: flex; align-items: center">
                    <el-dropdown-item @click="downloadChart('svg')">
                        <el-button class="item" type="primary" @click="downloadChart('svg')">Download SVG</el-button>
                    </el-dropdown-item>
                    <!-- <el-radio v-model="radio3" label="300">300 dpi</el-radio>
          <el-radio v-model="radio3" label="600">600 dpi</el-radio>
          <el-radio v-model="radio3" label="900" style="margin-right: 20px"
            >900 dpi</el-radio
          > -->
                </div>
                <el-dropdown-item @click="downloadChart('gif')" style="margin-top: 10px;">
                    <el-button class="item" type="primary" @click="downloadChart('gif')" style="width: 100%">Download
                        GIF</el-button>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <!-- <div class="tip2" style="">
      目前PNG、TIFF、SVG格式下载的图片DPI＜300，PDF的DPI大于500，如果用于SCI文章发表需选择PDF格式，导出矢量PDF后可通过WPS或PS/AI软件任意编辑全图的标题、坐标轴、图例和标签的字体大小、位置、添加/删除横线等细节调整。
    </div> -->

        <el-button v-if="haveCode == 0" id="downBut" @click="downCode" type="success" round style="display: none">
            代码下载</el-button>
        <el-button v-else id="downBut" @click="downCode" type="success" round style="margin-left: 15px">
            代码下载</el-button>
        <div v-if="modalOpen1" class="modal">
            <div class="modal-content content-height" ref="modalContent" :style="{
          top: modalY + 'px',
          left: modalX + 'px',
          position: 'absolute',
          padding: '30px',
          width: '666px',
        }" @mousedown="startDrag">
                <!-- overflow: 'auto', -->
                <!-- maxHeight: '760px', -->
                <span class="close" style="right: 3px; top: -5px" @click="closeModal1">&times;</span>
                <!-- <el-collapse
          v-model="activeNames"
          @change="handleChange"
          accordion
          style="
            padding-right: 10px;
            margin-right: -10px;
            overflow: auto;
            height: 94%;
          "
        > -->
                <!-- <el-collapse-item
            v-for="(item, index) in chartsList.data"
            :key="item.id"
            :title="item.title"
            :name="String(index + 1)"
          > -->
                <div class="login-all" style="
            display: flex;
            justify-content: center;
            height: 100%;
            align-items: center;
          ">
                    <div class="container1" id="codeText" v-html="convertedHTML"
                        style="overflow: auto; text-align: center"></div>
                </div>
                <!-- <div class="num"> {{chartsNumber[index]}} </div> -->
                <!-- </el-collapse-item> -->
                <!-- </el-collapse> -->
                <div class="Konw" style="
            color: red;
            width: 100%;
            text-align: center;
            line-height: 42px;
            position: absolute;
            right: 0px;
            bottom: 9px;
          ">
                    <span style="
              color: #fff;
              display: flex;
              height: 100%;
              width: 93px;
              margin: 0px auto;
              justify-content: center;
              background: #ffa900;
              border-radius: 5px;
              cursor: pointer;
            " @click="copyText()">一键复制</span>
                </div>
                <!-- <div
          class="login-all"
          style="
            display: flex;
            justify-content: center;
            height: 100%;
            align-items: center;
          "
        >
          <div
            class="container1"
            v-html="convertedHTML"
            style="max-height: 580px; overflow: auto; text-align: center;overflow-x: hidden;"
          ></div>
        </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import html2canvas from "html2canvas";
    import { saveAs } from "file-saver";
    import jsPDF from "jspdf";
    import "svg2pdf.js";
    import svgformat from "../../assets/svg/svgformat";
    import { Loading, Message } from "element-ui"; //项目中引用
    import changedpi from "@/utils/png.js";
    import bus from "@/utils/bus";
    export default {
        props: {
            doms: {
                type: HTMLDivElement,
            },
            isTrue: {
                type: Boolean,
                default: false,
            },
            is3D: {
                type: Boolean,
                default: false,
            },
            fatherMethod: {
                type: Function,
                require: true,
                default: null,
            },
            t: {
                type: Number,
                require: true,
                // default: 10,
            }
        },
        data() {
            return {
                loadinginstace: null,
                showSecondDiv1: false,
                showSecondDiv: false,
                radio: 3,
                radio1: 600,
                radio2: 600,
                radio3: 600,
                loadinginstace: null,
                // 转码后的内容
                convertedHTML: "",
                // 是否是SVIP
                IsSVIP: null,
                lastTimeList: null,
                // 最后下载的时间
                lastTime: null,
                // 图形编码
                chartCode: null,
                // 图形是否有可下载代码
                haveCode: null,
                isCode: null,
                // 模态框的数据
                modalOpen1: false,
                isDragging1: false,
                mouseX: 0,
                mouseY: 0,
                modalX: 0,
                modalY: 0,
                offsetX: 0,
                offsetY: 0,
            };
        },
        mounted() {
            bus.$on("Send_Vip_Data", (data) => {
                // console.log(data);
                this.IsSVIP = data.user.vip;
                this.lastTime = data.user.lvctime;
            });
            // 获取图形编码
            bus.$on("codeNumber", (data) => {
                console.log(data);
                this.chartCode = data[0].pid;
                this.haveCode = data[0].code;
            });

            this.lastTimeList = JSON.parse(localStorage.getItem("Send_last_time"));
            // console.log(this.lastTimeList);
            // this.myHeader = data.user.headimgurl;
            // console.log(this.myHeader);
            // this.VIP_Num = data;
            // console.log(this.VIP_Num);
            const script = document.createElement('script');
            script.src = 'https://cdn.jsdelivr.net/npm/ccapture.js@1.1.0/build/CCapture.all.min.js';  // Adjust the path based on your static file location
            document.body.appendChild(script);
        },
        methods: {
            // 下载代码
            // downCode(){
            //   console.log('我要下载代码');
            // 条件一
            // if ('用户要是SVIP') {
            // 条件二
            //   if ('首先当前的时间戳-最后一次下载时的时间戳>604800') {
            // 此时获取到的是后台返回的那些代码信息，将后台的html码进行转换然后展示就完活
            //   }else{
            // 直接将后台的报错信息提示出来就行了
            // }
            // },
            // }else{
            // 弹窗是否去充值
            // },
            downCode() {
                // console.log("复制代码");
                const now = new Date();
                const timestamp = now.getTime();

                console.log(timestamp / 1000, this.IsSVIP); // 输出当前时间的时间戳
                if (this.IsSVIP == 2) {
                    // this.lastTimeList.filter((num, index, arr) => {
                    //   console.log(num, index, arr);
                    //   // return num > 10;
                    // });1728725134
                    for (let i = 0; i < this.lastTimeList.length; i++) {
                        for (let j = 0; j < this.lastTimeList[i].projects.length; j++) {
                            console.log(this.lastTimeList[i]);
                            if (this.lastTimeList[i].projects[j].pid == this.chartCode) {
                                // 判断是否有code代码
                                console.log(this.lastTimeList[i].projects[j].code);
                                // if (this.lastTimeList[i].projects[j].code != 0) {
                                // 是否大于剩余时间

                                // console.log(
                                //   "您可以下载相关代码",
                                //   timestamp / 1000 - this.lastTime
                                // );
                                let formData = new FormData();
                                formData.append("type", "code");
                                formData.append("gid", this.chartCode);
                                this.axios({
                                    url: "https://cnsknowall.com/api/view", // 设置请求的地址
                                    method: "POST", // 设置请求方法
                                    data: {
                                        // get请求使用params进行参数凭借,如果是post请求用data
                                        type: "code",
                                        gid: this.chartCode,
                                    },
                                })
                                    .then((res) => {
                                        console.log(res);
                                        if (res.data.error == "") {
                                            console.log(res);
                                            this.openModal1();
                                            let tempDiv = document.createElement("div");
                                            tempDiv.innerHTML = res.data.data;

                                            // 获取解码后的 HTML
                                            const convertedHTML =
                                                tempDiv.textContent || tempDiv.innerText;
                                            // console.log(convertedHTML);
                                            // 在控制台输出解码后的 HTML

                                            this.convertedHTML = convertedHTML.replace(
                                                /<img/g,
                                                '<img class="images1"'
                                            );
                                            // console.log(this.convertedHTML);
                                            // console.log(document.querySelectorAll("p"));
                                            this.$nextTick(() => {
                                                // console.log(document.querySelectorAll("p"));
                                                const imglist = document.querySelectorAll("p");
                                                // console.log(imglist);

                                                // 遍历集合并控制样式
                                                imglist.forEach(function (pElement) {
                                                    // console.log(pElement);
                                                    // 添加或修改样式属性
                                                    pElement.style.height = "100%"; // 修改文字颜色为红色
                                                    pElement.style.overflow = "auto"; // 修改字体大小为 16 像素
                                                    pElement.style.overflowX = "hiden";
                                                    pElement.style.textAlign = "left"; // 修改字体加粗
                                                    pElement.style.marginTop = "10px"; // 修改上边距为 10 像素
                                                });
                                            });
                                        } else {
                                            this.$message({
                                                showClose: true,
                                                message: res.data.error,
                                                type: "warning",
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error.response);
                                        this.$message({
                                            showClose: true,
                                            message: "上传失败",
                                            type: "error",
                                        });
                                    });
                                // } else {
                                //   // const downBut = document.getElementById("downBut");
                                //   // console.log(downBut);
                                //   this.$message({
                                //     showClose: true,
                                //     message: "该图暂未查询到相关代码",
                                //     type: "warning",
                                //   });
                                // }
                            }
                            // else {
                            //   console.log(6666);
                            //   this.$message({
                            //     showClose: true,
                            //     message: "该图暂未查询到相关代码",
                            //     type: "warning",
                            //   });
                            //   return
                            // }
                            // this.lastTimeList[i][j].projects.filter((num, index, arr) => {
                            //   console.log(num, index, arr);
                            //   // return num > 10;
                            // });
                        }
                        //   this.lastTimeList[i].filter(
                        //     (num, index, arr) => {
                        //   console.log(num, index, arr);
                        //   // return num > 10;
                        // }
                        //   )
                    }
                    // if (timestamp - ) {

                    // }
                } else {
                    this.$message({
                        showClose: true,
                        message: "请前往个人中心升级SVIP",
                        type: "warning",
                    });
                }
            },
            showDiv2() {
                this.showSecondDiv = true;

                // console.log(666, this.showSecondDiv);
            },
            hideDiv2() {
                this.showSecondDiv = false;
            },
            showDiv3() {
                this.showSecondDiv1 = true;

                // console.log(666, this.showSecondDiv1);
            },
            hideDiv3() {
                this.showSecondDiv1 = false;
            },
            //解决加载字体卡顿问题
            _runTask(task, callback) {
                requestIdleCallback((idle) => {
                    // console.log(idle.timeRemaining())
                    if (idle.timeRemaining() > 0) {
                        // const font1 = document.createElement("link");
                        // font1.href = "fonts/bold.ttf";
                        // font1.rel = "stylesheet";
                        // document.head.appendChild(font1);
                        // const font2 = document.createElement("link");
                        // // font2.href = "fonts/normal.ttf";
                        // font2.rel = "stylesheet";
                        // document.head.appendChild(font2);
                        const ele = this.$props.doms;
                        const element = ele.querySelector("svg");

                        console.log(element);
                        svgformat();
                        const svgWidth = element.getAttribute("width");
                        const svgHeight = element.getAttribute("height");
                        let doc = null;
                        if (svgHeight <= svgWidth || svgWidth >= 1000) {
                            doc = new jsPDF("landscape", "pt", [
                                parseFloat(svgWidth),
                                parseFloat(svgHeight),
                            ]);
                        } else if (svgHeight > svgWidth) {
                            doc = new jsPDF("landscape", "pt", [
                                parseFloat(svgHeight),
                                parseFloat(svgHeight),
                            ]);
                        }

                        // doc.addFont("fonts/normal.ttf", "msyh", "normal");
                        doc.addFont("fonts/bold.ttf", "msyh", "bold");

                        doc.svg(element).then(() => {
                            this.loadinginstace.close();
                            doc.save("chart.pdf");
                        });
                    }
                });
            },
            runTask(task) {
                return new Promise((resolve) => {
                    this.loadinginstace = Loading.service({
                        lock: true,
                        text: "正在疯狂的下载中，请稍后。。。",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.5)",
                    });
                    this._runTask(task, resolve);
                });
            },
            getPDf() {
                const ele = this.$props.doms;
                console.log(ele, ele.id);
                // 动态获取id下面的div和svg元素
                let element = ele.querySelector(`#${ele.id} svg`).parentNode.innerHTML;
                console.log(element);
                // element = JSON.stringify(element);
                // 将参数值设置到隐藏的input元素中
                document.getElementById("paramInput").value = element;

                // 提交表单
                document.getElementById("pdfForm").submit();
            },
            getTiff() {
                const ele = this.$props.doms;
                console.log(ele, ele.id);
                // 动态获取id下面的div和svg元素
                // let element = ele.querySelector(`#${ele.id} svg`).parentNode.innerHTML;
                let element1 = ele.querySelector(`svg`);
                let element = element1.querySelectorAll("path");
                console.log(element1, element1.width, element);
                element.forEach(function (path) {
                    // 检查 path 元素的 fill 属性是否为 "transparent"，如果是，则添加 fill-opacity 属性
                    if (path.getAttribute("fill") === "transparent") {
                        path.setAttribute("fill-opacity", "0");
                    }
                });

                let element2 = element1.parentNode.innerHTML;
                console.log(element, element1, element2);
                // element = JSON.stringify(element);
                // 将参数值设置到隐藏的input元素中
                // if (element2.includes("\x3C!---->")) {
                //   element2.replace("/\x3C!---->/g", ""); // 删除所有字符串
                // }
                console.log(element2);
                if (element2.includes("<!---->")) {
                    // console.log('需要进行删除',console.log(element2 ));
                    let arr = element2.split("<!---->");
                    element2 = arr.join(""); // 删除空格
                    console.log(element2);
                }

                document.getElementById("paramInput1").value = element2;
                document.getElementById("paramInput12").value = this.radio1;
                // 提交表单
                document.getElementById("pdfForm1").submit();
            },
            downLoadGifImg() {
                let canvasDom = this.$props.doms; // 获取渲染 ECharts 的 canvas 元素
                let svg = canvasDom.querySelector("svg")

                let canvas = document.createElement('canvas');
                let ctx = canvas.getContext('2d');
                document.body.appendChild(canvas);
                canvas.width = svg.width.animVal.value;  // 设置你想要的宽度
                canvas.height = svg.height.animVal.value; // 设置你想要的高度

                let totalTime = this.$props.t // 捕获总时间（秒）
                let framerate = 62; // 帧率
                let totalFrames = totalTime * framerate; // 计算总帧数
                let currentFrame = 0;

                console.log(this.$props.t, 999888);


                const capturer = new CCapture({
                    format: 'gif',
                    workersPath: '/',
                    framerate: framerate,
                    backgroundColor: '#FFFFFF'
                });

                capturer.start();

                const that = this

                function toBase64(str) {
                    return btoa(unescape(encodeURIComponent(str)));
                }
                function render() {
                    if (currentFrame < totalFrames) {
                        let svgString = new XMLSerializer().serializeToString(svg);
                        let img = new Image();

                        img.onload = function () {
                            ctx.clearRect(0, 0, canvas.width, canvas.height);
                            ctx.drawImage(img, 0, 0);
                            capturer.capture(canvas); // 捕获帧
                            currentFrame++; // 递增帧数
                            requestAnimationFrame(render); // 请求下一帧
                        };

                        img.src = 'data:image/svg+xml;base64,' + toBase64(svgString);
                    } else {
                        capturer.stop();
                        capturer.save(function (blob) {
                            let a = document.createElement('a');
                            a.href = URL.createObjectURL(blob);
                            a.download = 'animation.gif';
                            a.click();
                            canvas.style.display = 'none'; // 隐藏 Canvas
                            that.loadinginstace.close();
                            that.$message({
                                showClose: true,
                                message: "已成功导出GIF",
                                type: "success",
                            });
                        });
                    }
                }

                this.loadinginstace = Loading.service({
                    lock: true,
                    text: "正在导出，请稍后。。。",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.6)",
                    customClass: 'el-zoom-in-bottom',
                    name: 'el-zoom-in-bottom',
                });

                render();
            },
            // 下载图片
            downloadChart(format) {
                if (this.$props.isTrue) {
                    const ele = this.$props.doms;

                    const element = ele.querySelector("svg");
                    console.log(ele, element);
                    if (format === "png") {
                        html2canvas(ele, { scale: this.radio, dpi: 600 }).then((canvas) => {
                            canvas.toBlob((blob) => {
                                saveAs(blob, "chart.png");
                            });
                        });
                    } else if (format === "tiff") {
                        this.getTiff();
                    } else if (format === "pdf") {
                        this.getPDf();
                        // doc.setFont("微软雅黑"); // 设置使用微软雅黑字体
                        // doc.addFont("fonts/bold.ttf", "msyh", "bold");
                        // this.runTask();
                    } else if (format === "svg") {
                        const blob = new Blob([element.outerHTML], {
                            type: "image/svg+xml",
                        });
                        saveAs(blob, "chart.svg");
                    } else if (format === "gif") {
                        this.fatherMethod();
                        this.downLoadGifImg();
                    }
                } else {
                    this.$message({
                        showClose: true,
                        message: "请上传数据后进行下载",
                        type: "warning",
                    });
                }
            },
            // 登录模态框的方法
            openModal1() {
                // if (this.NewsBody != null) {
                this.modalOpen1 = true;
                // this.getwitchVIP();
                this.$nextTick(() => {
                    this.centerModal();
                });
                // }
            },
            closeModal1() {
                this.modalOpen1 = false;
            },
            // 明天将这个样式进行转换，以及css样式进行规划
            copyText(text) {
                const codeText = document.getElementById("codeText").innerText;
                // this.modalOpen1 = false;
                navigator.clipboard
                    .writeText(codeText)
                    .then(() => {
                        this.$message({
                            showClose: true,
                            message: "复制成功",
                            type: "success",
                        });
                    })
                    .catch((err) => {
                        console.error("复制失败", err);
                    });
                // 退出后，清除定时器
                // this.$message({
                //   showClose: true,
                //   message: "取消支付",
                //   type: "warning",
                // });
            },
            startDrag(event) {
                this.isDragging = true;
                this.mouseX = event.clientX;
                this.mouseY = event.clientY;
                const modalContent = this.$refs.modalContent;
                const modalRect = modalContent.getBoundingClientRect();
                // console.log(modalContent);

                this.offsetX = this.mouseX - modalRect.left;
                this.offsetY = this.mouseY - modalRect.top;
                document.addEventListener("mousemove", this.dragModal);
                document.addEventListener("mouseup", this.stopDrag);
            },
            dragModal(event) {
                if (!this.isDragging) return;
                const newX = event.clientX - this.offsetX;
                const newY = event.clientY - this.offsetY;
                this.modalX = newX;
                this.modalY = newY;
            },
            stopDrag() {
                this.isDragging = false;
                document.removeEventListener("mousemove", this.dragModal);
                document.removeEventListener("mouseup", this.stopDrag);
            },
            centerModal() {
                const modalContent = this.$refs.modalContent;
                const modalRect = modalContent.getBoundingClientRect();
                const windowWidth =
                    window.innerWidth || document.documentElement.clientWidth;
                const windowHeight =
                    window.innerHeight || document.documentElement.clientHeight;
                const modalWidth = modalRect.width;
                const modalHeight = modalRect.height;
                this.modalX = (windowWidth - modalWidth) / 2;
                this.modalY = (windowHeight - modalHeight) / 2;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .total:hover .tip2 {
        display: block !important;
    }

    .total {
        .modal {
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 99;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);

            ::v-deep #login_wx .impowerBox .info p {
                display: none !important;
            }

            body {
                background-color: #666;
            }

            /* 左右布局 */
            .txt {
                width: 149px;
                background-image: url(@/assets/img/login-tip.svg);
                position: absolute;
                top: 20px;
                right: 56px;
                width: 164px;
                height: 36px;
                padding: 8px 0 0 8px;
                font-size: 14px;
                font-weight: 400;
                color: #005cfa;
                line-height: 20px;
                letter-spacing: 0.25px;
                -webkit-user-select: none;
                user-select: none;
            }

            .container {
                display: flex;
                width: 847px;
                height: 546px !important;
                align-items: center;
                background: #fff;
                padding: 0;

                .left {
                    text-align: center;

                    .top {
                        font-size: 18px;
                        font-weight: 700;
                        color: #ccc;
                    }

                    .center {
                        font-size: 23px;
                        font-weight: 700;
                        color: #ccc;
                    }
                }
            }

            .container .left .img img {
                width: 375px;
            }

            .justify-content-center {
                width: 100%;
                text-align: center;
            }

            .passport-ctr {
                width: 320px;
            }

            /* 设置placeholder样式 */
            .form-group>input::placeholder {
                color: #bfbfbf;
                font-size: 14px;
            }

            .form-group {
                text-align: left;
            }

            #form_login {
                /* border: 1px solid #cccccc; */
                border-radius: 2px;
                background: #ffffff;
                padding: 20px 28px;
                margin-top: 30px;
                margin-left: -20px;
            }

            .passport-link {
                text-align: center;
                /* border: 1px solid #cccccc; */
                border-radius: 2px;
                background: #ffffff;
                margin-top: 17px;
                line-height: 45px;
                height: 45px;
                width: 320px;
                margin-left: -20px;
            }

            .socialite-desc {
                text-align: center;
                line-height: 2;
                margin-top: 17px;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .line-border {
                display: inline-block;
                height: 1px;
                width: 60px;
                background-color: #ccc;
                line-height: 60px;
            }

            .line-center {
                margin: 0 30px;
            }

            /* 弹出框样式 */
            .popup {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .popup-content {
                width: 398px;
                height: 546px;
                background-color: #fff;
                padding-top: 80px;
                position: relative;
            }

            .close-btn {
                position: absolute;
                top: 0px;
                right: 0px;
                cursor: pointer;
                background-color: #f1f1f1;
                border: none;
                /* padding: 8px 12px; */
            }

            .close-btn img {}

            /* .close-btn .back {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 170px;
  top: 447px;
  width: 141px;
  height: 52px;
  background: #fff;
} */
            /* Fade transition */
            .fade-enter-active,
            .fade-leave-active {
                transition: opacity 0.3s ease;
            }

            .fade-enter,
            .fade-leave-to {
                opacity: 0;
            }

            /* 设置整体的最小宽度 */
            .col-4 {
                min-width: 300px;
            }

            .yanzheng {
                position: absolute;
                right: 0;
                font-size: 14px;
                font-weight: 400;
                text-align: center;
                color: #999;
                line-height: 24px;
                transform: translate(-13px, -38px);
                /* pointer-events: none; */
            }

            /* 输入框的样式 */
            .form-control:hover {
                border: 1px solid #007bff;
            }

            .impowerBox {
                margin-top: 30px !important;
            }

            .container {
                min-height: 546px;
            }

            ::v-deep input::-webkit-outer-spin-button,
            ::v-deep input::-webkit-inner-spin-button {
                -webkit-appearance: none !important;
            }

            ::v-deep input[type="number"] {
                -moz-appearance: textfield;
            }

            .content-height {
                height: 70%;
            }

            .modal-content {
                background-color: #fefefe;
                padding: 20px;
                border: 1px solid #888;
                cursor: move;
                user-select: none;
                min-width: 360px;
                min-height: 260px;

                // position: absolute;
                .scrolling-text {
                    white-space: nowrap;
                    display: inline-block;
                    animation: scrollText linear infinite;
                }

                @keyframes scrollText {
                    from {
                        transform: translateX(100%);
                    }

                    to {
                        transform: translateX(-100%);
                    }
                }
            }

            .close {
                color: #aaa;
                float: right;
                font-size: 30px;
                font-weight: bold;
                cursor: pointer;
                position: absolute;
                right: 10px;
                top: 16px;
            }

            .close:hover,
            .close:focus {
                color: black;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }

    .tip2 {
        display: none;
        position: absolute;
        left: 163px;
        bottom: 0;
        width: 300px;
        font-size: 14px;
        color: #999;
        text-align: justify;
    }
</style>