<template>
  <div style="display: flex; align-items: center; margin: 10px 0; height: 5%">
    <label>文件名：</label>
    <el-input placeholder="请输入内容" v-model="input" :disabled="true" style="width: 38%; height: 40px">
    </el-input>
    <span class="example" @click="openModal">选择文件</span>
    <Example v-if="isExample" :xlsxData="lists"></Example>
    <!-- 模态框 -->
    <div v-if="modalOpen" class="modal">
      <div class="modal-content" ref="modalContent" :style="{ top: modalY + 'px', left: modalX + 'px' }"
        @mousedown="startDrag">
        <span class="close" @click="closeModal">&times;</span>
        <h3 class="top">上传文件</h3>
        <el-upload ref="upload" :action="baseUrl + '/upload/'+ $route.query.pid" :data="params" :drag="true" :auto-upload="true"
          class="upload-demo" :on-success="handleSuccess" :before-upload="getbefore" :multiple="true"
          :with-credentials="true" :limit="1">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
    </div>
  </div>
</template>

<script>
import Example from "../ExampleVeen.vue";
import { Loading, Message } from "element-ui";
export default {
  components: {
    Example,
  },
  props: {
    interfaces: {
      type: String,
    },
    lists: {
      type: Array,
    },
    params: {
      type: Object,
      default: () => ({
        projectname: "",
      }),
    },
    isExample: {
      type: Boolean,
      default: true,
    },
    istxt: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 模态框的数据
      modalOpen: false,
      isDragging: false,
      mouseX: 0,
      mouseY: 0,
      modalX: 0,
      modalY: 0,
      offsetX: 0,
      offsetY: 0,
      baseUrl: window.g.baseUrl,
      input: "",
      listsData: [],
      loadinginstace: null,
    };
  },
  methods: {
    // 文件拖拽上传
    handleSuccess(response, file, fileList) {
      // 处理上传成功后的操作
      // console.log("上传成功", response);
      this.modalOpen = false;
      this.input = file.name
      if (
        response.error == "User Logged in elsewhere" ||
        response.error == "User Not Login"
      ) {
        this.$message({
          showClose: false,
          message: response.error,
          type: "error",
        });
        this.$router.push("/");
      } else {
        if (response.error.length > 0) {
          this.$message({
            showClose: false,
            message: response.error,
            type: "error",
          });
        } else if (response.error.msg) {
          this.$message({
            showClose: false,
            message: response.error.msg,
            type: "error",
          });
        }
        else {
          this.listsData = response;
          this.$emit("handleSuccess", this.listsData);
        }
      }
    },
    getbefore(file) {
      this.$emit("beforeUpload", file);
      let istxt = this.$props.istxt;
      if (istxt) {
        const type = file.type === "text/plain";
        if (!type) {
          this.$message.error("上传的文件只能是 txt 格式!");
        } else {
          this.modalOpen = false;
          this.loadinginstace = Loading.service({
            lock: true,
            text: "正在处理，请稍后。。。",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.5)",
          });
        }
        return type;
      }
      else {
        let type =
          file.type === "text/csv" ||
          file.type === "text/plain" ||
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          file.type === "application/vnd.ms-excel" ||
          file.type === "";

        const fileName = file.name;

        // 定义允许的压缩包文件扩展名列表
        const allowedExtensions = [
          ".zip",
          ".cab",
          ".jar",
          ".tar",
          ".gz",
          ".z",
          ".rar",
          ".7z",
        ];

        // 提取文件扩展名
        const fileExtension = fileName
          .substring(fileName.lastIndexOf(".") + 1)
          .toLowerCase();

        if (!type) {
          if (allowedExtensions.includes("." + fileExtension)) {
            //是否为压缩包
            type = true;
            // this.modalOpen = false;
            // this.loadinginstace = Loading.service({
            //   lock: true,
            //   text: "正在处理，请稍后。。。",
            //   spinner: "el-icon-loading",
            //   background: "rgba(0, 0, 0, 0.5)",
            // });
          } else {
            this.$message.error("上传的文件格式有误!");
          }
          return type;
        } else {
        }
        return type;
      }
    },
    // 模态框的方法
    openModal() {
      this.modalOpen = true;
      this.$nextTick(() => {
        this.centerModal();
      });
    },
    closeModal() {
      if (this.$refs.upload.uploadFiles.length > 0) {
        this.$confirm('关闭后将会中断上传，确定关闭吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 中止文件上传
          this.$refs.upload.abort();
          this.modalOpen = false;
        }).catch(() => {
        });
      } else {
        this.modalOpen = false;
      }

    },
    startDrag(event) {
      this.isDragging = true;
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
      const modalContent = this.$refs.modalContent;
      const modalRect = modalContent.getBoundingClientRect();
      this.offsetX = this.mouseX - modalRect.left;
      this.offsetY = this.mouseY - modalRect.top;
      document.addEventListener("mousemove", this.dragModal);
      document.addEventListener("mouseup", this.stopDrag);
    },
    dragModal(event) {
      if (!this.isDragging) return;
      const newX = event.clientX - this.offsetX;
      const newY = event.clientY - this.offsetY;
      this.modalX = newX;
      this.modalY = newY;
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener("mousemove", this.dragModal);
      document.removeEventListener("mouseup", this.stopDrag);
    },
    centerModal() {
      const modalContent = this.$refs.modalContent;
      const modalRect = modalContent.getBoundingClientRect();
      const windowWidth =
        window.innerWidth || document.documentElement.clientWidth;
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const modalWidth = modalRect.width;
      const modalHeight = modalRect.height;
      this.modalX = (windowWidth - modalWidth) / 2;
      this.modalY = (windowHeight - modalHeight) / 2;
      // console.log(666);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload {

  // 修改处的css，6.13
  .example {
    display: inline-block;
    padding: 5px 7px;
    text-align: center;
    white-space: nowrap;
    font-size: 14px;
    border: none;
    vertical-align: top;
    height: 35px;
    line-height: 25px;
    color: #fff;
    background-color: #0294a7;
    border-color: #026c7a;
    -webkit-box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin-left: 10px;
    border-radius: 5px;
    cursor: pointer;
  }

  .example1 {
    color: #006cbf;
    font-size: 14px;
    margin-left: 10px;
  }

  // 模态框样式
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    cursor: move;
    user-select: none;
    position: absolute;
  }

  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  // fuzi复制样式
  .copyAll {
    button {
      margin-top: 10px;
      cursor: pointer;
    }

    .copy {
      .fixed-size {
        resize: none;
        width: 300px;
        /* 设置宽度 */
        height: 200px;
        /* 设置高度 */
      }

      table {
        table-layout: fixed;
        /* 使用固定表格布局 */
      }

      th {
        white-space: nowrap;
        /* 不换行 */
        overflow: hidden;
        /* 隐藏溢出文本 */
        text-overflow: ellipsis;
        /* 当文本溢出时显示省略号 */
      }
    }
  }

  .dropzone {
    border: 2px dashed #ccc;
    padding: 20px;
    cursor: pointer;

    &:hover {
      border-color: #aaa;
    }

    .upFile {
      margin-top: 15px;
      display: flex;
      align-items: center;

      .example {
        display: inline-block;
        padding: 5px 8px;
        font-size: 14px;
        border: none;
        vertical-align: top;
        height: 32px;
        color: #fff;
        background-color: #0294a7;
        border-color: #026c7a;
        -webkit-box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
      }

      .input22 {
        width: 80%;
        border: none;
        border-radius: 5px;
        padding: 10px;
        width: 100%;
        font-size: 16px;
        color: #666666;
        background-color: #f5f5f5;
        box-shadow: inset 0 0 5px #cccccc;
      }
    }

    .but {
      width: 100px;
      margin-top: 15px;
    }
  }
}
</style>