<template>
    <div>
      <div class="md-modal md-effect-8" id="modal-8" :style="myStyle1">
        <div style="width: 100%; height: 100%; background-color: #fff;"></div>
        <!-- 控制模态框的拖拽和缩放 -->
        <VueDragResize 
          :isDraggable="draggable"
          :isActive="false"
          :w="mowidth"
          :h="moHeight"
          :minw="500"
          :minh="450"
          v-if="status"
          >
          <div class="md-content" style="background: #fff; width: 100% !important;">
            <div class="contents">
              <p>示例文件</p>
              <div style="display: flex; align-items: center; margin-bottom: 10px;">
                <i class="el-icon-minus" @click="minus"></i>
                <i class="el-icon-plus" @click="plus"></i>
                <el-button type="primary" size="medium" @click="derive">导出Excel</el-button>
              </div>
            </div>
            <div :style="myStyle3">
                <div class="tablelist">
                    <p class="listitem" v-for="(item ,index) in xlsxData" :key="index" @click="Toggle(index)">{{'文件'+(index+1)}}</p>
                </div>
                <template v-for="(item,index) in xlsxData">
                  <hot-table :data="item" :settings="hotSettings" v-show="index==defaul">{{item}}</hot-table>
                </template>
            </div>
          </div>
        </VueDragResize>
        <!-- 放大后 -->
        <div v-else class="md-content" style="background: #fff; height: 100vh !important; width: 100vw !important;">
          <div class="contents">
            <p>示例文件</p>
            <div style="display: flex; align-items: center; margin-bottom: 10px;">
              <i class="el-icon-minus" @click="minus"></i>
              <i class="el-icon-plus" @click="plus"></i>
              <el-button type="primary" size="medium" @click="derive">导出Excel</el-button>
            </div>
          </div>
          <div :style="myStyle3">
            <div class="tablelist">
                <p class="listitem" v-for="(item ,index) in xlsxData" :key="index" @click="Toggle(index)">{{'文件'+(index+1)}}</p>
            </div>
            <template v-for="(item,index) in xlsxData">
              <hot-table :data="item" :settings="hotSettings" v-show="index==defaul">{{item}}</hot-table>
            </template>
          </div>
        </div>
      </div>
      <div class="containers">
        <div class="main clearfix">
          <div class="column">
            <span class="md-trigger example1" data-modal="modal-8" @click="popup">示例文件</span>
          </div>
        </div>
      </div>
      <div class="md-overlay"></div>
    </div>
  </template>
  
  <script>
  import * as XLSX from 'xlsx'
  import { HotTable } from '@handsontable/vue';
  import { registerAllModules } from 'handsontable/registry';
  import 'handsontable/dist/handsontable.full.css';
  import VueDragResize from 'vue-drag-resize';  //引入组件（需要注册）

  registerAllModules();
  
  export default {
    props: {
      xlsxData: {
        type: Array,
        default: function () {
          return Array.from({ length: 1000 }, () => Array(26).fill(""))
        }
      }
    },
    data() {
      return {
        draggable:true,//控制组件移动触发
        resizable:true,
        resize: '', 
        mowidth: 900,  
        moHeight: 600, 
        data1: [],
        hotSettings: {
          // data: this.xlsxData,
          rowHeaders: true,
          colHeaders: true,
          width: "100%",
          height: '100%',
          colWidths: 100,
          rowHeights: 25,
          manualColumnResize: true,
          licenseKey: "non-commercial-and-evaluation",
          minRows: 30, // 最少行数(当初始化数据小于该值时,以该值为准)
          minCols: 30,
        },
        myStyle1:{
          width:900+'px'
        },
        myStyle3:{ 
          height: 80+'%',
        },
        defaul:0,
        status:true,
      };
    },
    components: {
      HotTable,
      VueDragResize,  //注册控制拖拽和缩放组件
    },
    created(){
        this.dataHandle()
    },
    mounted(){
      this.dataHandle()
      this.feature()
      this.resizable=true
    },
    methods: {
      Toggle(i){
        this.defaul=i
        this.dataHandle()
      },
      popup(){
        
      },
      //一键缩小模态框
      minus(){
        this.mowidth = 900
        this.moHeight = 600
        this.myStyle1 = {  //动态添加样式
          width:900+'px',
        }
        this.myStyle3 = {
          height: 80+'%',
        }
        this.status=true
      },
      //一键放大模态框
      plus(){
        this.myStyle1 = {  //动态添加样式
          width: 100+'% !important',
        }

        this.myStyle3 = {
          height: 86+'%',
        }
        this.status=false
      },
      //模态框的功能效果
      feature(){
        function classReg(className) {
          return new RegExp("(^|\\s+)" + className + "(\\s+|$)");
        }
    
        var hasClass, addClass, removeClass;
    
        if ('classList' in document.documentElement) {
          hasClass = function (elem, c) {
            return elem.classList.contains(c);
          };
          addClass = function (elem, c) {
            elem.classList.add(c);
          };
          removeClass = function (elem, c) {
            elem.classList.remove(c);
          };
        }
        else {
          hasClass = function (elem, c) {
            return classReg(c).test(elem.className);
          };
          addClass = function (elem, c) {
            if (!hasClass(elem, c)) {
              elem.className = elem.className + ' ' + c;
            }
          };
          removeClass = function (elem, c) {
            elem.className = elem.className.replace(classReg(c), ' ');
          };
        }
    
        function toggleClass(elem, c) {
          var fn = hasClass(elem, c) ? removeClass : addClass;
          fn(elem, c);
        }

        var classie = {
          hasClass: hasClass,
          addClass: addClass,
          removeClass: removeClass,
          toggleClass: toggleClass,
          has: hasClass,
          add: addClass,
          remove: removeClass,
          toggle: toggleClass
        };

        if (typeof define === 'function' && define.amd) {
          define(classie);
        } else {
          window.classie = classie;
        }

          var overlay = document.querySelector('.md-overlay');
          var trigger = document.querySelector('.md-trigger');
          var modal = document.querySelector('#' + trigger.getAttribute('data-modal'))

          function removeModal(hasPerspective) {
            classie.remove(modal, 'md-show');
            if (hasPerspective) {
              classie.remove(document.documentElement, 'md-perspective');
            }
          }

          function removeModalHandler() {
            removeModal(classie.has(trigger, 'md-setperspective'));
          }

          trigger.addEventListener('click', function (ev) {
            classie.add(modal, 'md-show'); 
            overlay.removeEventListener('click', removeModalHandler);
            overlay.addEventListener('click', removeModalHandler);

            if (classie.has(trigger, 'md-setperspective')) {
              setTimeout(function () {
                classie.add(document.documentElement, 'md-perspective');
              }, 25);
            }
          });
      },
      //数据处理
      dataHandle(){
        this.data1=[]
        this.data1=[...this.xlsxData[this.defaul]]
      },
      //下载示例文件
      derive(){
        //一键下载所有的示例文件

        for (let i = 0; i < this.xlsxData.length; i++) {
          // 创建一个 Workbook 对象
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet(this.xlsxData[i]);
      
            // 将工作表添加到 Workbook 中
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      
            // 将 Workbook 导出为 XLSX 文件
            const buffer = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const fileName = '示例数据.xlsx';
      
            // 创建一个下载链接并触发点击下载
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
          }

          //单个下载示例文件（页面显示哪个示例文件下载哪个文件）

          // // 创建一个 Workbook 对象
          // const wb = XLSX.utils.book_new();
          // const ws = XLSX.utils.aoa_to_sheet(this.data1);
    
          // // 将工作表添加到 Workbook 中
          // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
          // // 将 Workbook 导出为 XLSX 文件
          // const buffer = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
          // const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          // const fileName = '示例数据.xlsx';
    
          // // 创建一个下载链接并触发点击下载
          // const link = document.createElement('a');
          // link.href = window.URL.createObjectURL(blob);
          // link.download = fileName;
          // link.click();
        }
    }
  };
  </script>
    
  <style>
    .tablelist{
        display: flex;
    }
    .listitem{
      font-size: 12px !important;
      padding: 0 !important;
      background-color: #0294a7;
      width: 50px !important;
      height: 20px !important;
      line-height: 20px !important;
      text-align: center;
      color: #fff;
      font-weight: 400 !important;
      opacity: 0.8;
      border-radius: 8px 8px 0 0;
      margin-top: 16px !important;
      margin-right: 2px !important;
      cursor:pointer;
    }
    /* 隐藏一圈的虚线 */
    .vdr.active:before {
      display:none;
    }
    /* 把缩放元素隐藏 显示缩放标志 */
    .vdr-stick-tl,.vdr-stick-br{
      opacity: 0;
    }
    .vdr-stick-tl,.vdr-stick-br:hover{
      cursor: nwse-resize !important;
    }
    .vdr-stick-tr,.vdr-stick-bl{
      opacity: 0;
    }
    .vdr-stick-tr,.vdr-stick-bl:hover{
      cursor: nesw-resize !important;
    }
    .vdr-stick-mr,.vdr-stick-ml{
      height: 530px !important;
      top: 15px;
      background:none;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    .vdr-stick-tm,.vdr-stick-bm{
      width: 700px !important;
      left: 15px;
      cursor: ns-resize !important;
      background:none;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    /* 放大缩小按钮 */
    .el-icon-minus{
      cursor: pointer !important;
      margin-right: 15px;
    }
    .el-icon-plus{
      cursor: pointer !important;
      margin-right: 20px;
    }

    .wtSpreader{
      width: 100% !important;
    }
    .htCore{
      width: 100% !important;
    }
   .ht_master .wtHider{
      width: 100% !important;
    }
    .ht_clone_top .wtHider{
      width: 100% !important;
    }
    .ht_clone_top .wtHider .wtSpreader tr {
      width: 50px !important;
    }
    .ht_clone_top .wtHider .wtSpreader tr th:nth-of-type(1){
      width: 50px !important;
    }
    .ht_master .wtHolder{
      width: 100% !important;
    }
    .handsontable{
      margin: 0 !important;
    }
    .hot-display-license-info{
      display: none;
    }


    .contents{
      display: flex;
      padding: 0 !important;
      justify-content: space-between;
      font-family: '楷体';
      background: rgba(0,0,0,0.1);
      width: 100%;
      padding:10px 25px 0 25px !important;
      box-sizing: border-box;
      cursor:move !important;
    }
    .md-content button{
        margin: 0 !important;
      }
    .md-perspective,
    .md-perspective body {
      height: 100%;
      overflow: hidden;
    }
  
    .md-perspective body  {
      background: #222;
      -webkit-perspective: 600px;
      -moz-perspective: 600px;
      perspective: 600px;
    }
  
    .containers {
      /* background: #e74c3c; */
      /* background: #fff; */
      min-height: 100%;
      margin: 0 !important;
      padding: 0 !important;
      box-shadow:none
    }
  
    .md-modal {
      position: fixed;
      left: 50%;
      top: 50%; 
      /* max-width: 630px; */
      /* min-width: 820px; */
      min-height: 600px;
      z-index: 2000;
      visibility: hidden;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
    }
  
    .md-show {
      visibility: visible;
    }
  
    .md-overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      visibility: hidden;
      top: 0;
      left: 0;
      z-index: 1000;
      opacity: 0;
      background: rgba(44, 35, 34, 0.5);  /* 弹窗遮罩层透明度 */
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
    }
  
    .md-show ~ .md-overlay {
      opacity: 1;
      visibility: visible;
    }
  
    /* Content styles */
    .md-content {
      color: #000000;
      position: relative;
      border-radius: 5px;
      margin: 0 auto;
      height: 100%;
      z-index: 1000;
    }
  
    .md-content p {
      padding: 0 !important;
      background-color: #0294a7;
      width: 70px;
      height: 30px;
      line-height: 28px;
      text-align: center;
      color: #fff;
      font-size: 15px;
      font-weight: 700;
      opacity: 0.8;
      border-radius: 8px 8px 0 0;
      margin-top: 16px !important;
    }
  
    .md-content > div {
      padding: 5px 40px 30px;
      margin: 0;
      font-weight: 300;
      font-size: 1.15em;
    }
  
    .md-content > div p {
      margin: 0;
      padding: 10px 0;
    }
  
    .md-content > div ul {
      margin: 0;
      padding: 0 0 30px 20px;
    }
  
    .md-content > div ul li {
      padding: 5px 0;
    }
  
    .md-content button {
      display: block;
      margin: 0 auto;
      font-size: 0.8em;
    }
  
  
  
  /* Effect 8: 3D flip horizontal */
  .md-effect-8.md-modal {
      -webkit-perspective: 1300px;
      -moz-perspective: 1300px;
      perspective: 1300px;
  }
  
  .md-effect-8 .md-content {
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-transform: rotateY(-70deg);
      -moz-transform: rotateY(-70deg);
      -ms-transform: rotateY(-70deg);
      transform: rotateY(-70deg);
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
      opacity: 0;
  }
  
  .md-show.md-effect-8 .md-content {
      -webkit-transform: rotateY(0deg);
      -moz-transform: rotateY(0deg);
      -ms-transform: rotateY(0deg);
      transform: rotateY(0deg);
      opacity: 1;
  }

  .example1{
    color: #006cbf;
    font-size: 14px;
    margin-left: 10px;
    cursor: pointer;
  }
  </style>