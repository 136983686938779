<template>
    <el-button type="success" size="medium" @click="recover" :disabled="disabled">参数保存</el-button>
</template>

<script>
export default {
    props: {
        isClick: {
            type: Boolean,
            default: true
        },
        parentRef: {
            type: Object,
            default: null
        },
        parentRef2: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            value: this.isClick,
        };
    },
    created() {
        if (this.$route.query.id) {
            console.log(this.$route.query.id)
        }
    },
    methods: {
        recover() {
            if (sessionStorage.getItem('Symble') == 1) {
                if (this.parentRef && typeof this.parentRef.toUpload === 'function') {
                    this.parentRef.toUpload(true);
                } else {
                    this.parentRef.uploadFile();
                }
            } else if (sessionStorage.getItem('Symble') == 2) {
                if (this.parentRef2 && typeof this.parentRef2.uploadSampleFile === 'function') {
                    this.parentRef2.uploadSampleFile(2);
                }
            }
            // this.parentRef.uploadSampleFile()
            this.$nextTick(() => {
                setTimeout(() => {
                    this.value = true
                    this.$emit("input", this.value);
                }, 500);

            })
        },
    },
}
</script>

<style scoped>
/* 样式 */
</style>