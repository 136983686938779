<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
  // 禁止查看打印台
  //   mounted(){
  //     if (window.console && window.console.log) {
  //   window.addEventListener("keydown", function(e) {
  //     if (e.keyCode == 123) { // F12键
  //       e.preventDefault();
  //     }
  //   });

  //   window.addEventListener("contextmenu", function(e) {
  //     e.preventDefault();
  //   });
  // }
  //   }
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}

#chart-box {
  max-width: 1200px !important;
  max-height: 83vh !important;
  overflow: auto !important;
}

form {
  .el-form-item {
    display: flex;
    justify-content: space-between;

    .el-form-item__content {
      margin-left: 0 !important;
    }
  }

  > ::before {
    display: none !important;
  }

  > ::after {
    display: none !important;
  }
}

.pie1Total,
.totalAll {
  margin-left: 0 !important;
  margin-top: 0 !important;
  width: 100% !important; //周末修改
  height: 99.5%; //6.13号修改 原数据为97
  .detailPages {
    .box {
      margin-bottom: 0;
    }
  }
  .bottom {
    height: 100%;
    background-color: #fff; //6.13号添加
    margin-top: 5px !important;
    .total1 {
      height: 100%;
      position: relative; //6.13号添加

      > .title {
        position: absolute; //解决按钮超出问题
        top: 10px;
      }
    }

    .echarts2 {
      padding-top: 70px !important; //12.6号修改
      height: 100% !important;

      > .title {
        position: absolute;
        top: 10px;
      }
    }
  }
}

html,
body,
#app {
  height: 100%;
  // min-height: 450px;
  background-color: #eee;
}

.home {
  min-height: 100%;
}

.total1 {
  padding: 30px;
  padding-top: 100px;
  // min-height: 500px;
  font-size: 13px;

  .adjust {
    width: 470px !important;

    .el-form-item__label {
      font-size: 13px;
      text-align: left;
    }

    // 3月五号为了让滚动条距离内容有点距离添加此代码
    #pane-1 {
      padding: 15px;
      padding-top: 0;
      padding-left: 0;
    }

    .div_container {
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }
    .items{
      .item{
          overflow: hidden;
          white-space: nowrap;
          /* 防止文字换行 */
          text-overflow: ellipsis;
      }
    }
    // .el-tabs--border-card>.el-tabs__content{
    //   padding: 0 !important;
    // }
  }
}

#app {
  // background-color: rgba(22, 22, 22, 0.1);
}
.all .bottomContain {
  overflow: auto;
}

.all {
  width: 98%;
  position: relative;
  height: calc(100% - 15px); //11

  > .right {
    padding-bottom: 30px !important;
    // 9.22
    min-width: 1200px !important;
    min-height: 600px;
  }
}

#testDiv {
  position: absolute;
  right: 0;
  top: 0;
  background-color: buttonface;
  background-repeat: repeat;
  background-attachment: scroll;
  color: #3969a5;
  /* // height: 660px; */
  overflow: auto;
  width: 880px;
  z-index: 2;
  /* border: 2px outset white; */
  margin: 0px;
  padding: 2px;
  background-position: 0% 50%;
}

/* 滚动条 */
.resizeMe::-webkit-scrollbar {
  width: 9px;
  /* 滚动条的宽度 */
  height: 9px;
}

.resizeMe::-webkit-scrollbar-thumb {
  background-color: #999;
  /* 滚动条滑块的颜色 */
  border-radius: 6px;
  /* 滚动条滑块的圆角 */
}

.resizeMe::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* 滚动条轨道的颜色 */
  border-radius: 6px;
  /* 滚动条轨道的圆角 */
}

.resizeMe::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* 滚动条滑块的颜色（鼠标悬停状态） */
  width: 12px;
}

.resizeMe::-webkit-scrollbar-thumb:active {
  background-color: #333;
  /* 滚动条滑块的颜色（鼠标按下状态） */
}

#innerNice {
  background-repeat: repeat;
  background-attachment: scroll;
  color: #3969a5;
  height: 100%;
  /* // overflow: auto; */
  max-width: 880px;
  min-width: 750px;
  padding: 8px;
  padding-left: 13px;
  background-position: 0% 50%;
  position: absolute;
  top: 0;
}

/* 底部滚动条 */
#innerNice::-webkit-scrollbar {
  width: 6px;
  /* 滚动条的宽度 */
}

.all .line {
  z-index: 99;
  height: 100%;
  width: 12px;
  background: #333;
  cursor: e-resize;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  position: sticky;
  left: 0;
  top: 0;
}

.all .line .img {
  width: 5px;
  height: 18px;
  /* vertical-align: middle; */
}

// height: calc(100vh - 205px);
.el-tabs__content {
  overflow: initial;
  position: initial;
  height: calc(100% - 50px);

  .el-tab-pane {
    height: 95%;
    overflow: initial;
  }
  // 5.27修改
  // #pane-upload {
  //   height: 96%;
  //   overflow: initial;
  // }

  #pane-1,
  #pane-2,
  #pane-3,
  #pane-4 {
    /* 2024.3.15更改 图形的图形设置，说明等距离底部距离过大 */
    // height: 102%;
    height: 95%;
    overflow: auto;
  }
}

.el-dropdown {
  margin-left: 20px;
}

.adjust {
  height: 100% !important;
  overflow-x: initial !important;
  overflow-y: initial !important;
}

label {
  white-space: nowrap;
}

.el-collapse-item__header {
  font-size: 18px !important;
  font-weight: 700 !important;
  border-bottom: 1px solid #ccc;
}

.el-collapse-item__wrap {
  border-bottom: 1px solid #ccc;
}

.upload {
  height: 100%;
}

.upFile {
  height: 100%;
}

.overbold {
  display: flex;
  align-items: center;
}

.highcharts-credits {
  display: none;
}

.right {
  height: auto !important;
}

/* 控制弹窗的白线条（9.25） */
.vdr-stick {
  opacity: 0 !important;
}

.handsontable {
  height: 100%;
}

/* 滚动条轨道 */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #dbe2e9;
}

// #8b8b8b
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #8b8b8b;
  border-radius: 4px;
}

/* 鼠标悬停时滚动条滑块 */
::-webkit-scrollbar-thumb:hover {
  background-color: #575656;
}

.lists-text {
  font-size: 13px;
}

.el-tabs__item {
  padding: 0 18px !important;
}

.el-result__extra {
  .el-button {
    margin-left: initial !important;
  }
}
</style>
