<template>
  <div
    class="login-all"
    style="
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
    "
  >
    <div class="container">
      <!-- 左边的图片 -->
      <div class="left">
        <div class="top"></div>
        <div class="center"></div>
        <div class="img">
          <img src="../assets/img/bgi1.png" alt="" />
          <!-- <img src="../assets/img/img1.png" alt="" /> -->
        </div>
      </div>
      <div class="popup-content">
        <iframe
          :src="targetURL"
          width="100%"
          height="100%"
          frameborder="0"
          scrolling="no"
        ></iframe>
        <!-- <div class="littleTip">注册登录后即可免费使用</div> -->
        <div class="close-btn" @click="closePopup">
          <div class="img" @click="togglePopup">
            <!-- <div class="txt">切换成手机号登录</div> -->
            <img src="../assets/img/weixin.png" alt="" />
          </div>
          <div class="back"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCountdown from "@chenfengyuan/vue-countdown";
// import bus from "../utils/bus";

export default {
  components: {
    VueCountdown,
  },
  data() {
    return {
      showPopup: true,
      url: "",
      targetURL: "",
      codemesage: true,
      phoneNumber: "",
      phoneCode: "",
      // 验证码参数s
      counting: false,
      // log_off: false //退出登录标志，如果为true就不自动登录
    };
    // 验证码状态
  },

  mounted() {
    this.loginAgain(); //检查是否已经登录
    //   const cookie = document.cookie;
    //   console.log(cookie);
    //   if (cookie === true) {
    //     var timeID = setTimeout(function () {
    //       this.$router.push({
    //         name: "BottomContainAll",
    //       });
    //     }, 2000);
    //     console.log(cookie);
    //   }
    const back = this.$route.query.back;
    if (back) this.url = back;
    else {
      const refer = document.referrer;
      if (refer) this.url = refer;
      else {
        const src = window.location.href;
        // console.log(src.split("/"));
        const allSrc = src.split("/");
        // console.log(allSrc[0] + "//" + allSrc[2], 6666);
        this.url = allSrc[0] + "//" + allSrc[2];
      }
    }
    // console.log("this.url:", this.url);
    this.targetURL =
      "https://open.weixin.qq.com/connect/qrconnect?appid=wx97dc2ccc715c8d87&redirect_uri=https://cnsknowall.com/api/WeChatLogin?back=" +
      encodeURIComponent(this.url) +
      "&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect";
    // console.log(this.targetURL);
    sessionStorage.setItem("activeIndex", 1);
    sessionStorage.setItem("default-active", "1-1");
  },

  methods: {
    // 检查是否已经登录如果已经登录就自动登录
    loginAgain() {
      let formData = new FormData();
      // 获取当前页面的 URL
      const currentUrl = window.location.href;
      console.log(currentUrl);
      // 对 URL 进行编码
      const encodedUrl = encodeURIComponent(currentUrl);
      // formData.append("code", this.phoneCode);
      // formData.append("phoneNumber", this.phoneNumber);
      this.axios
        .post("https://cnsknowall.com/api/islogin?p=" + encodedUrl, {
          withCredentials: true,
        })
        .then((response) => {
          // console.log(response.data); //后台返回的数据
          // this.log_off = localStorage.getItem("log_off");//退出登录标志，如果为true就不自动登录/
          if (response.data.islogin === true) {
            sessionStorage.setItem("activeIndex", 1);
            sessionStorage.setItem("default-openeds", [1]);
            sessionStorage.setItem("default-active", "1-1");

            this.$message({
              showClose: true,
              message: "您已登录",
              type: "success",
            });
            this.$router.push({
              name: "BottomContainAll",
            });
          }
        })
        .catch(() => {
          // console.log(error.response.data)
          this.$message({
            showClose: true,
            message: "请重新登陆",
            type: "error",
          });
        });
    },

    // 验证码方法
    startCountdown: function () {
      this.counting = true;
      this.phoneNumber = this.$refs.inputphone.value;
      // console.log(this.phoneNumber);
      let formData = new FormData();
      formData.append("phoneNumber", this.phoneNumber);

      // const phoneReg = /(^1[34578]\d{9}$)/;
      const phoneReg =
        /^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|192|198|199|(147))\d{8}$/;
      if (phoneReg.test(this.phoneNumber)) {
        this.axios
          .post("https://cnsknowall.com/api/sendSmsVerification", formData, {
            withCredentials: true,
          })
          .then((response) => {
            // console.log(response.data); //后台返回的数据

            this.$message({
              showClose: true,
              message: "发送成功",
              type: "success",
            });
          })
          .catch(() => {
            // console.log(error.response.data)
            this.$message({
              showClose: true,
              message: "发送失败",
              type: "error",
            });
          });
      } else {
        this.$message({
          showClose: true,
          message: "您输入的不是一个合法的手机号",
          type: "error",
        });
      }
    },
    onCountdownEnd: function () {
      this.counting = false;
    },
    // end
    togglePopup() {
      this.showPopup = !this.showPopup;
    },
    closePopup() {
      this.showPopup = false;
    },

    getPhoneCode() {
      this.phoneCode = this.$refs.phoneCode.value;
      // console.log(this.phoneCode);
    },
    loginUp() {
      let formData = new FormData();
      formData.append("code", this.phoneCode);
      formData.append("phoneNumber", this.phoneNumber);
      this.axios
        .post("https://cnsknowall.com/api/LoginWithPhoneNumber", formData, {
          withCredentials: true,
          //  headers: {
          //   "Content-Type": "multipart/form-data",
          // },
        })
        .then((response) => {
          // console.log(response.data); //后台返回的数据

          if (response.data.code === 1) {
            // bus.$emit('send',response.data.data.userid)

            // localStorage.setItem("user_id", response.data.data.userid);

            sessionStorage.setItem("default-active", "1-1");
            // localStorage.setItem("aside_index", '1-1');
            sessionStorage.setItem("activeIndex", 1);
            this.$message({
              showClose: true,
              message: "已上传成功",
              type: "success",
            });
            top.location.href = this.url;
            // this.$router.push({
            //   name: "HomePage",
            // });
          }
        })
        .catch(() => {
          // console.log(error.response.data)
          this.$message({
            showClose: true,
            message: "上传失败",
            type: "error",
          });
        });
      //  bus.$emit('send',6666)
      //  this.$router.push({
      //         name: "BottomContainAll",
      //       });
    },
  },
};
</script>
<style src="@/assets/CSS/LoginCss.css"  scoped>
</style>
<style lang="scss" scoped>
/* 自己添加的样式 */

::v-deep #login_wx .impowerBox .info p {
  display: none !important;
}
/* .info {
  display: none !important;
} */
body {
  background-color: #666;
}
/* 左右布局 */
.txt {
  width: 149px;
  background-image: url(../assets/img/login-tip.svg);
  position: absolute;
  top: 20px;
  right: 56px;
  width: 164px;
  height: 36px;
  padding: 8px 0 0 8px;
  font-size: 14px;
  font-weight: 400;
  color: #005cfa;
  line-height: 20px;
  letter-spacing: 0.25px;
  -webkit-user-select: none;
  user-select: none;
}
.container {
  display: flex;
  width: 847px;
  height: 546px !important;
  align-items: center;
  background: #fff;
  padding: 0;
}
.container .left .img img {
  width: 375px;
}
.justify-content-center {
  width: 100%;
  text-align: center;
}
.passport-ctr {
  width: 320px;
}
/* 设置placeholder样式 */
.form-group > input::placeholder {
  color: #bfbfbf;
  font-size: 14px;
}
.form-group {
  text-align: left;
}
#form_login {
  /* border: 1px solid #cccccc; */
  border-radius: 2px;
  background: #ffffff;
  padding: 20px 28px;
  margin-top: 30px;
  margin-left: -20px;
}
.passport-link {
  text-align: center;
  /* border: 1px solid #cccccc; */
  border-radius: 2px;
  background: #ffffff;
  margin-top: 17px;
  line-height: 45px;
  height: 45px;
  width: 320px;
  margin-left: -20px;
}
.socialite-desc {
  text-align: center;
  line-height: 2;
  margin-top: 17px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.line-border {
  display: inline-block;
  height: 1px;
  width: 60px;
  background-color: #ccc;
  line-height: 60px;
}
.line-center {
  margin: 0 30px;
}

/* 弹出框样式 */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  width: 472px;
  height: 547px;
  background-color: #fff;
  padding-top: 80px;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  background-color: #f1f1f1;
  border: none;
  /* padding: 8px 12px; */
}
.close-btn img {
}
/* .close-btn .back {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 170px;
  top: 447px;
  width: 141px;
  height: 52px;
  background: #fff;
} */
/* Fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/* 设置整体的最小宽度 */
.col-4 {
  min-width: 300px;
}
.yanzheng {
  position: absolute;
  right: 0;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #999;
  line-height: 24px;
  transform: translate(-13px, -38px);
  /* pointer-events: none; */
}
/* 输入框的样式 */
.form-control:hover {
  border: 1px solid #007bff;
}
.impowerBox {
  margin-top: 30px !important;
}
.container {
  min-height: 546px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>