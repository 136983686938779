// 1.导入axios
import axios from "axios";
import { Loading, Message } from 'element-ui';

// const baseURL = 'http://192.168.3.22:8888'
// const baseURL = 'http://192.168.3.31:8888'
const baseURL = window.g.baseUrl
const baseURL2 = window.g.baseUrl2
axios.defaults.withCredentials = true
const _axios = axios.create({
  baseURL,
  // timeout: 10000, //超时时间
  headers: {
    "Content-Type": "multipart/form-data",
  },
  withCredentials: true
});
 
// 添加请求拦截器

_axios.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

 let loadinginstace
// 添加响应拦截器
_axios.interceptors.response.use(
  response => {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    
    return response;
  },
  error => {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    
    if (error.code === 'ECONNABORTED' || error.message === "Network Error" || error.message.includes("timeout")) {
      Message({
        showClose: true,
        message: '请求失败,请刷新后重试',
        type: 'error'
      });
      loadinginstace && loadinginstace.close();
    }
    if (error.response) {

      let errorMessage = error.response.data === null ? '系统内部异常.' : error.response.data.message;
      switch (error.response.status) {
        case 404:
          Message({
            showClose: true,
            message: '很抱歉，资源未找到',
            type: 'error'
          });
          break;
        case 403:
        case 401:
          Message({
            showClose: true,
            message: '很抱歉，您无法访问该资源，可能是因为没有相应权限',
            type: 'error'
          });
          break;
        default:
          Message({
            showClose: true,
            message: errorMessage,
            type: 'error'
          });
          break;
      }
    }
    loadinginstace && loadinginstace.close();
    return Promise.reject(error);
  }
);
const request = {
  post(url, params) {
    return _axios.post(baseURL + url, params);
  },
  get(url, params) {
    return _axios.get(baseURL2 + url, {
      params: params,
    });
  },
};

export default request;