<template>
  <el-tooltip :content="num.toString()" placement="top">
    <div class="fontbox">
      <i
        class="el-icon-remove-outline"
        style="font-size: 20px; cursor: pointer !important"
        @click="change(true)"
      ></i>
      <el-slider
        @change="getvalue"
        v-model="num"
        :min="min"
        :max="max"
        :step="step"
        :show-tooltip="false"
        style="width: 100px; margin: 0 5px"
      ></el-slider>
      <i
        class="el-icon-circle-plus-outline"
        style="font-size: 20px; cursor: pointer !important"
        @click="change(false)"
      ></i>
    </div>
  </el-tooltip>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 50,
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      num: this.value,
    };
  },
  methods: {
    getvalue() {
      this.$emit("input", this.num); // 通过 input 事件更新父组件的数据
      this.$emit("drawChart"); // 触发自定义事件
    },
    change(val) {
      if (!val) {
        if (this.num < this.$props.max) {
          if (this.$props.step % 1 !== 0) {
            this.num =
              (parseFloat(this.num) + parseFloat(this.$props.step)).toFixed(1) *
              1;
          } else {
            this.num = this.num * 1 + this.$props.step;
          }
        }
      } else {
        if (this.num > this.$props.min) {
          if (this.$props.step % 1 !== 0) {
            this.num =
              (parseFloat(this.num) - parseFloat(this.$props.step)).toFixed(1) *
              1;
          } else {
            this.num = this.num * 1 - this.$props.step;
          }
        }
      }
      this.$emit("input", this.num); // 通过 input 事件更新父组件的数据
      this.$emit("drawChart"); // 触发自定义事件
    },
  },
};
</script>

<style scoped>
.fontbox {
  margin-right: 50px;
  display: flex;
  align-items: center;
  cursor: pointer !important;
}
i {
  cursor: pointer !important;
}
::v-deep div {
  cursor: pointer !important;
}
</style>