import Vue from 'vue';

//echarts 图形自适应方法
export const handleResize = _.debounce(function (chart) {
    if (chart) {
        chart.resize();
    }
}, 200)

//获取历史记录上传数据
export const locationData = async function locationData(id, newData = false) {
    let params = {
        id
    };
    try {
        const response = await Vue.prototype.$api.getHistoryData(params);
        if (response == '') {
            this.$message({
                showClose: true,
                message: '资源未找到,请检查该记录是否已被删除',
                type: 'error'
            });
        } else {
            if (newData) {
                return response.data;
            } else {
                return response.data.res;
            }

        }
    } catch (error) {
        console.error('Error fetching data:', error);
        this.$message({
            showClose: true,
            message: error,
            type: 'error'
        });
        // 可以根据需要处理错误
        throw error;
    }
}

//气泡图计算气泡大小函数
/**
 * 
 * @param {*} value 气泡的值 
 * @param {*} max 最大气泡数据
 * @param {*} min 最小气泡值数据
 * @param {*} desiredMin 自定义最大气泡值
 * @param {*} desiredMax 自定义最小气泡值
 * @returns 
 */
export const calculateSymbolSize = function (value, max, min, desiredMin, desiredMax) {
    // console.log(value,123)
    // if (value === 0) {
    //     return 5;
    // }

    // 避免除以零
    if (max === min) {
        return (desiredMax - desiredMin) + desiredMin;
    }

    return (
        ((value - min) / (max - min)) * (desiredMax - desiredMin) + desiredMin
    );
}
/**
 * //判断是否为小数，是的话保留小数位
 * @param {*} val 传入的值
 * @param {*} toFixed 保留小数位
 * @returns 
 */
export const formatDecimal = function formatDecimal(val, toFixed) {
    // 检查是否为小数
    if (Number.isInteger(val)) {
        return val; // 如果是整数，直接返回
    } else {
        // 如果是小数，保留一位小数
        return val.toFixed(toFixed);
    }
}
/**
 * //示例数据数组转json
 * @param {*} data 传入的数组
 * @returns 
 */
export const transformData = function transformData(data) {
    let result = {};
    data.forEach((row, rowIndex) => {
        result[rowIndex] = {
            cells: {}
        };
        row.forEach((cell, cellIndex) => {
            result[rowIndex].cells[cellIndex] = { text: cell === null ? '' : String(cell) };
        });
    });

    return result;
}

export const convertStringToNumber = function (data) {
    // console.log(data)  
    return data.map(row =>
        row.map(cell =>
            cell === "" ? null : (!isNaN(cell) && cell !== '' ? Number(typeof (cell) == 'string' ? cell.trim() : cell) : (typeof (cell) == 'string' ? cell.trim() : cell))
        ),
    );
}

/**
 * //检查是否为数组
 */
export const isArray = function isArray(data) {
    try {
        return Array.isArray(data);
    } catch (e) {
        return false;
    }
}

/**
 * //检查是否为对象
 */
export const isObject = function isObject(data) {
    try {
        return data !== null && typeof data === 'object' && !Array.isArray(data);
    } catch (e) {
        return false;
    }
}

/**
 * 根据当前小数位自动保留有效位
 * @param {*} num 值
 * @param {*} minSigFigs 保留最小位数
 * @param {*} maxSigFigs 保留最大位数
 * @returns 
 */
export const formatSmartFigures = function formatSmartFigures(num, minSigFigs = 2, maxSigFigs = 3) {
    if (num === 0) return 0;
    if (Number.isInteger(num)) return num;

    const absNum = Math.abs(num);

    // 动态决定有效数字位数
    const sigFigs =
        absNum >= 1
            ? minSigFigs // 大于等于 1 的小数保留最少有效数字
            : Math.min(maxSigFigs, Math.ceil(-Math.log10(absNum))); // 小数保留更多位数

    return Number(num.toPrecision(sigFigs));
}



export default {
    install(Vue) {
        Vue.prototype.$handleResize = handleResize;
        Vue.prototype.$locationData = locationData;
        Vue.prototype.$calculateSymbolSize = calculateSymbolSize;
        Vue.prototype.$formatDecimal = formatDecimal;
        Vue.prototype.$transformData = transformData;
        Vue.prototype.$convertStringToNumber = convertStringToNumber;
        Vue.prototype.$isArray = isArray;
        Vue.prototype.$isObject = isObject;
        Vue.prototype.$formatSmartFigures = formatSmartFigures;
    },
};