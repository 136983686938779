<template>
    <!-- <div class="tool" style="width: 90%; position: absolute; bottom: 11px; display: flex; justify-content: end;"> -->
        <el-button type="primary" size="medium" @click="recover" :disabled="disabled">参数复原</el-button>
        <!-- <el-button type="success" size="medium" @click="save">参数保存</el-button> -->
        <!-- <p>{{ parentRef }}</p> -->
    <!-- </div> -->
</template>

<script>
export default {
    model: {
        prop: 'chartParams',
        event: 'update:chartParams'
    },
    props: {
        chartParams: {
            type: Object,
            required: true
        },
        disabled:{
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            localChartParams: JSON.parse(JSON.stringify(this.chartParams)),
        };
    },
    mounted() {
        sessionStorage.setItem("chartParams", JSON.stringify(this.chartParams));
    },
    methods: {
        recover() {
            this.localChartParams = JSON.parse(sessionStorage.getItem("chartParams"));
            this.$emit("update:chartParams", this.localChartParams);
            this.$emit('drawChart'); // 触发自定义事件
        },
    },
    // watch: {
    //     chartParams: {
    //         handler(newValue) {
    //             this.localChartParams = JSON.parse(JSON.stringify(newValue));
    //             this.sizeKey++; // 更新 key，强制 AxisVal 组件重新渲染
    //         },
    //         deep: true
    //     }
    // }
}
</script>

<style scoped>
/* 样式 */
</style>