import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const WGCNA = [
    {
        path: "/WGCNA",
        name: "WGCNA",
        component: () => import("@/views/biologicalInformationAnalysis/other/WGCNA/WGCNA.vue"),
        // children: [
        //     {
        //         path: "WGCNA_1",
        //         name: "WGCNA_1",
        //         component: () => import("@/views/biologicalInformationAnalysis/WGCNA/PLS-DA.vue"),
        //     }
        // ]
    },

]
const WithTypes = WGCNA.map(item => {
    if (!item.meta) {
        // 没有meta对象
        return {
            ...item,
            meta: {
                type: "WGCNA",
            },
        };
    }

    // 有meta对象
    return {
        ...item,
        meta: {
            ...item.meta,
            type: "WGCNA", // 替换成你想要的类型值
        },
    };
});

export default WithTypes