<template>
    <div style="margin: 20px 0;display: flex;align-items: center;justify-content: space-between;position: relative;">
        <label class="labelFontSize item">{{label}}：</label>
        <el-select v-model="location" placeholder="请选择" @change="locationFun">
            <el-option v-for="item in option" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: 'top'
        },
        label:{
            type: String,
            default: '标签位置'
        },
        option: {
            type: Array,
            default: () => ([
                {
                    label: 'top',
                    value: 'top'
                },
                {
                    label: 'inside',
                    value: 'inside'
                },
                {
                    label: 'insideTop',
                    value: 'insideTop'
                },
                {
                    label: 'insideBottom',
                    value: 'insideBottom'
                },
            ]),

        }
    },
    data() {
        return {
            location: this.value,
        }
    },
    methods: {
        locationFun() {
            this.$emit('input', this.location); // 通过 input 事件更新父组件的数据
            this.$emit('drawChart'); // 触发自定义事件
        },
    }
}
</script>

<style></style>