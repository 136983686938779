<template>
  <div>
    <el-button
      v-if="!isPNG"
      style="margin-left: 20px"
      type="primary"
      :disabled="isClick"
      @click="download"
      >{{ label }}</el-button
    >
    <el-button
      v-else
      style="margin-left: 20px"
      type="primary"
      :disabled="isClick"
      @click="downPNG"
      >{{ label }}</el-button
    >
    <!-- <el-button type="primary" v-if="imgurl!==null" @click="lookImg">图片预览</el-button> -->
  </div>
</template>

<script>
import { Loading } from "element-ui";
export default {
  data() {
    return {
      loadinginstace: null,
    };
  },
  props: {
    isClick: {
      type: Boolean,
      default: true,
    },
    link: {
      type: String,
      default: "",
    },
    imgurl: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "下载表格",
    },
    isPNG:{
      type: Boolean,
      default: false,
    }
  },
  methods: {
    //导出Excel
    async download() {
      try {
        let arr = await this.$api.downloadPackage(this.link);
        if (arr instanceof Object && "error" in arr) {
          this.$message({
            showClose: true,
            message: arr.error,
            type: "error",
          });
        } else {
          window.location.href = window.g.baseUrl2 + this.link;
        }
      } catch (error) {
        this.$message({
          showClose: true,
          message: "下载出错",
          type: "error",
        });
      }
    },
    lookImg() {
      try {
        window.open("https://cnsknowall.com" + this.imgurl, "_blank");
      } catch (error) {
        this.$message({
          showClose: true,
          message: "预览失败，请重试",
          type: "error",
        });
      }
    },
    downPNG(){
        const downloadLink = window.g.baseUrl2+this.link; // 服务器返回的 PNG 链接
        fetch(downloadLink)
          .then(response => response.blob())  // 将响应转为 Blob 对象
          .then(blob => {
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);  // 创建一个 URL 对象链接
              link.download = 'image.png';  // 指定下载的文件名
              link.click();  // 触发点击事件
          })
          .catch(error => {
              console.error('Download error:', error);
          });
      }
  },
};
</script>

<style></style>