import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const biologicalInformationAnalysis = [
    {
        path: "transcriptomeAnalysis",
        name: "transcriptomeAnalysis",
        component: () => import("@/views/biologicalInformationProcess/transcriptomeAnalysis/transcriptomeAnalysis.vue"),
        meta: {
            title: "转录组分析",

        },
    },
        // 基因ID转换
        {
            path: "geneConversion",
            name: "geneConversion",
            component: () => import("@/views/tableFormat/geneConversion"),
            meta: {
                title: "转录组分析",
    
            },
        },
    // {
    //     // 桑基气泡图
    //     path: "SankeyBubbleDiagram",
    //     name: "SankeyBubbleDiagram",
    //     component: () =>
    //         import("@/views/AdvancedAnalysis/enrichmentAnalysis/SankeyBubbleDiagram"),
    //     meta: {
    //         title: "桑基气泡图1",

    //     },
    // },

]
const WithTypes = biologicalInformationAnalysis.map(item => {
    if (!item.meta) {
        // 没有meta对象
        return {
            ...item,
            meta: {
                type: "转录组分析",
            },
        };
    }

    // 有meta对象
    return {
        ...item,
        meta: {
            ...item.meta,
            type: "转录组分析", // 替换成你想要的类型值
        },
    };
});

export default WithTypes