<template>
    <el-collapse-item title="图形大小">
        <AxisVal :label="'宽度-高度：'" :key="sizeKey" v-model="size" @drawChart="sizes"></AxisVal>
        <div v-if="grid[0] !== null"
            style="margin: 20px 0; display: flex; align-items: center; justify-content: space-between;">
            <label class="labelFontSize item" for="labelFontSize">上边距:&nbsp;&nbsp;&nbsp;</label>
            <el-input @change="gridFun" type="number" v-model="grid[0]" placeholder="请输入内容"
                style="width: 217px;"></el-input>
        </div>
        <div v-if="grid[1] !== null"
            style="margin: 20px 0; display: flex; align-items: center; justify-content: space-between;">
            <label class="labelFontSize item" for="labelFontSize">下边距:&nbsp;&nbsp;&nbsp;</label>
            <el-input @change="gridFun" type="number" v-model="grid[1]" placeholder="请输入内容"
                style="width: 217px;"></el-input>
        </div>
        <div v-if="grid[2] !== null"
            style="margin: 20px 0; display: flex; align-items: center; justify-content: space-between;">
            <label class="labelFontSize item" for="labelFontSize">左边距:&nbsp;&nbsp;&nbsp;</label>
            <el-input @change="gridFun" type="number" v-model="grid[2]" placeholder="请输入内容"
                style="width: 217px;"></el-input>
        </div>
        <div v-if="grid[3] !== null"
            style="margin: 20px 0; display: flex; align-items: center; justify-content: space-between;">
            <label class="labelFontSize item" for="labelFontSize">右边距:&nbsp;&nbsp;&nbsp;</label>
            <el-input @change="gridFun" type="number" v-model="grid[3]" placeholder="请输入内容"
                style="width: 217px;"></el-input>
        </div>
    </el-collapse-item>
</template>

<script>
import bus from "@/utils/bus";
export default {
    props: {
        value: {
            type: Array,
            default: () => ([null, null, null, null, null, null])
        },
        chart: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            dom: null,
            grid: this.value,
            size: [this.value[4], this.value[5]],
            sizeKey: 0,
        }
    },
    created() {
        bus.$on("dom", (data) => {
            this.$nextTick(()=>{
                this.dom = data
                this.updateDomSize();
            })
        });
    },
    beforeDestroy() {
        this.resetChartSize(); // 重置图形大小
    },
    watch: {
        value: {
            handler(newVal) {
                this.grid = newVal;
                this.size = [this.grid[4], this.grid[5]];
                this.updateDomSize()
                bus.$emit("size", this.size);
                this.sizeKey++; // 更新 key，强制 AxisVal 组件重新渲染
                
            },
            deep: true,
        },
        chart:{
            handler(newVal) {
                this.chart.resize()
                this.$nextTick(()=>{
                    this.sizes()
                })
            },
        },
    },
    methods: {
        resetChartSize() {
            this.size = [null, null];
            this.grid = [null, null, null, null, null, null];
        },
        updateDomSize() {
            if (this.dom) {
                this.dom.style.width = this.size[0] + "px";
                this.dom.style.height = this.size[1] + "px";
            } else {
                console.warn("DOM element is not available.");
            }
        },
        gridFun() {
            this.$emit('drawChart'); // 触发自定义事件
            this.sizes()
        },
        sizes() {
            if (this.chart !== null) {
                this.dom.style.width = this.size[0] + "px";
                this.dom.style.height = this.size[1] + "px";
                this.grid = [this.grid[0], this.grid[1], this.grid[2], this.grid[3], ...this.size]
                this.$emit('input', this.grid); // 通过 input 事件更新父组件的数据
                this.chart.resize()
                this.$nextTick(() => {
                        bus.$emit("size", this.size);
                })
            }
        }
    }
}
</script>

<style></style>