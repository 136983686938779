<template>
  <!-- 复制数据 -->
  <div class="copyAll">
    <el-tabs class="copy" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        v-for="(item, index) in tabItems"
        :key="index"
        :label="label[index]"
        :name="item.name"
      >
        <div class="incre">
          <div class="tips">
            <i class="pic"
              ><svg
                t="1708582038326"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="1698"
                width="20"
                height="26"
              >
                <path
                  d="M511.7 1024c-65.6 0-129.6-12.3-190.2-36.6-61.4-24.6-116.9-60.5-164.8-106.7C108.9 834.6 71 780.6 44.1 720.1 16.2 657.5 1.4 590.7 0.1 521.6c-1.3-69.1 11-136.4 36.5-200 24.6-61.4 60.5-116.9 106.7-164.8C189.4 108.9 243.4 71 303.9 44.1 366.5 16.2 433.3 1.4 502.4 0.1c69.1-1.3 136.4 11 200 36.5 61.4 24.6 116.9 60.5 164.8 106.7C915.1 189.4 953 243.5 979.9 304c27.9 62.6 42.7 129.4 44 198.5 1.3 69.1-11 136.4-36.5 200-24.6 61.4-60.5 116.9-106.7 164.8C834.6 915.2 780.5 953.1 720 980c-62.6 27.9-129.4 42.7-198.5 44h-9.8z m0.6-969.1c-2.9 0-5.9 0-8.8 0.1-61.7 1.2-121.3 14.4-177.2 39.2-54 24-102.2 57.9-143.4 100.7-41.2 42.8-73.2 92.3-95.2 147.1C64.8 398.8 53.9 458.8 55 520.6c1.2 61.7 14.4 121.3 39.2 177.2 24 54 57.9 102.2 100.7 143.4 42.8 41.2 92.3 73.2 147.1 95.2 56.8 22.8 116.8 33.7 178.5 32.6 61.7-1.2 121.3-14.4 177.2-39.2 54-24 102.2-57.9 143.4-100.7 41.2-42.8 73.2-92.3 95.2-147.1 22.8-56.8 33.7-116.8 32.6-178.5-1.2-61.7-14.4-121.3-39.2-177.2-24-54-57.9-102.2-100.7-143.4-42.8-41.2-92.3-73.2-147.1-95.2-54-21.8-111-32.8-169.6-32.8z"
                  fill="#707070"
                  p-id="1699"
                ></path>
                <path
                  d="M512 793.3c-5.5 0-10.3-0.8-15-2.4-4.7-2.4-9.5-5.5-12.6-8.6-4-4-7.1-7.9-8.7-12.6-2.4-4.8-3.2-10.2-3.2-15.8 0-10.2 3.9-20.5 11.8-27.6 14.2-14.9 40.1-14.9 55.1 0 7.9 7.2 11.8 17.4 11.8 27.6 0 5.5-0.8 11-3.2 15.8-1.6 4.7-4.7 8.6-8.7 12.6-6.8 7.1-17 11-27.3 11z m0-173.1c-15.2 0-27.5-12.3-27.5-27.5v-323c0-15.2 12.3-27.5 27.5-27.5s27.5 12.3 27.5 27.5v323c0 15.2-12.3 27.5-27.5 27.5z"
                  fill="#707070"
                  p-id="1700"
                ></path></svg
            ></i>
            <span class="tit"
              >数据量过大(行数＞1万)时请通过"选择文件"方式上传数据</span
            >
          </div>
          <span class="el-dropdown-link">
            <i class="el-icon-plus" @click="incre(index)"></i>
          </span>
          <span class="el-dropdown-link">
            <i class="el-icon-minus" @click="desc(index)"></i>
          </span>
        </div>
        <div class="all">
          <div class="resizeMe" id="testDiv" @mousedown="doDown">
            <div class="line">
              <img
                src="../../assets/tubiao1.png"
                style="width: 5px; height: 18px"
              />
            </div>
            <div class="innerTab">
              <hot-table
                :ref="`hotTable${index}`"
                :settings="hotSettings"
                :data="item.data"
              ></hot-table>
            </div>
          </div>
        </div>
        <el-button
          size="mini"
          type="primary"
          @click="clear(index)"
          style="position: absolute; bottom: 20px; right: 20px"
        >
          清空
        </el-button>
      </el-tab-pane>
    </el-tabs>
    <el-button
      type="success"
      @click="uploadSampleFile"
      style="position: absolute; bottom: 20px"
      >上传</el-button
    >
  </div>
</template>

<script>
import { HotTable } from "@handsontable/vue";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import Handsontable from "handsontable";
import { Loading, Message } from "element-ui";
import bus from "../../utils/bus";
import { registerLanguageDictionary, zhCN } from "handsontable/i18n";
registerLanguageDictionary(zhCN);
registerAllModules();

export default {
  props: {
    interface: {
      type: String,
    },
    xmldata: {
      type: Array,
    },
    xmldata1: {
      type: Array,
    },
    xmldata2: {
      type: Array,
    },
    params: {
      type: Object,
      default: () => ({
        projectname: "",
        file1: null,
        file2: null,
        file3: null,
      }),
    },
    label: {
      type: Array,
      default: () => ["数据一", "数据二", "数据三"],
    },
  },
  components: {
    HotTable,
  },
  data() {
    return {
      tabItems: [],
      VIP_Num: null,
      hotSettings: {
        data: Array.from({ length: 1000 }, () => Array(26).fill()),
        rowHeaders: true,
        colHeaders: true,
        width: "100%",
        colWidths: 100,
        // rowHeights: 25,
        manualColumnResize: true,
        contextMenu: true,
        copyPaste: true, // 启用复制和粘贴功能
        manualRowMove: true,
        manualColumnMove: true,
        manualRowResize: true,
        manualColumnResize: true,
        licenseKey: "non-commercial-and-evaluation",
        viewportRowRenderingOffset: 20, // 设置虚拟滚动的渲染偏移量
      },
      language: "zh-CN", // 使用中文
      activeName: "first",
      theobject: null, //拖拽效果数据,
      data1: [],
      data2: [],
      data3: [],
      loadinginstace: null,
    };
  },
  created() {
    bus.$on("Send_Vip_Data", (data) => {
      this.VIP_Num = data.user.vip;
      // console.log(this.VIP_Num);
    });
    this.tabItems = [
      { name: "first", data: this.data1 },
      { name: "two", data: this.data2 },
      { name: "three", data: this.data3 },
      // 可以根据需要添加更多的tab项
    ];
  },
  mounted() {
    // 初始化拖拽功能
    document.onmouseup = this.doUp;
    document.onmousemove = this.doMove;
    this.dataHandle();
    this.$nextTick(() => {
      const hotInstance = this.$refs.hotTable0[0].hotInstance;
      hotInstance.updateSettings({ language: "zh-CN" });
      const hotInstance2 = this.$refs.hotTable1[0].hotInstance;
      hotInstance2.updateSettings({ language: "zh-CN" });
      const hotInstance3 = this.$refs.hotTable2[0].hotInstance;
      hotInstance3.updateSettings({ language: "zh-CN" });
    });

    // const doMoveDebounced = debounce(this.doMove, 16); // 控制频率为60fps

    // document.onmousemove = (event) => {
    //   doMoveDebounced(event);
    // };
  },
  methods: {
    // 上传示例文件
    // 上传示例文件
    uploadSampleFile() {
      if (this.VIP_Num == 1 || this.VIP_Num == 2) {
        const trimString = (str) => {
          if (typeof str === "string") {
            return str.trim();
          }
          return str;
        };

        const isNullArray = (arr) => arr.every((item) => item === null);
        const isDuplicateArray = (arr) => new Set(arr).size === 0;

        const processArrayToCsv = (dataArray) => {
          return dataArray
            .map((row) =>
              row
                .filter((cell) => cell !== "" && cell !== null)
                .map((cell) => trimString(cell))
                .filter((cell) => cell !== "")
            )
            .filter(
              (row) =>
                row.length > 0 && !isNullArray(row) && !isDuplicateArray(row)
            )
            .map((row) => row.join(","))
            .join("\r\n");
        };

        const csvContent = processArrayToCsv(this.data1);
        const csvContent2 = processArrayToCsv(this.data2);
        const csvContent3 = processArrayToCsv(this.data3);
        console.log(csvContent, csvContent2, csvContent3);
        const createCsvBlob = (csvContent) => {
          return new Blob([csvContent], { type: "text/csv" });
        };

        this.$props.params.file1 = new File(
          [createCsvBlob(csvContent)],
          "data.csv"
        );
        this.$props.params.file2 = new File(
          [createCsvBlob(csvContent2)],
          "data.csv"
        );
        this.$props.params.file3 = new File(
          [createCsvBlob(csvContent3)],
          "data.csv"
        );

        this.dialogVisible = false;
        let t;
        let xhr = null;
        let time = 60;
        this.loadinginstace = Loading.service({
          lock: true,
          text: "正在处理，请稍后。。。",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.5)",
        });
        t = setInterval(() => {
          time--;
          if (time > 0) {
            switch (true) {
              case time <= 30 && 0 < time:
                this.loadinginstace.text =
                  "您提交的数据量较大，请耐心等待。。。";
                break;
              default:
                break;
            }
          } else {
            clearInterval(t);
            // if (xhr) {
            //     xhr.abort();
            // }
            this.loadinginstace.close();

            this.$confirm("正在处理，可在我的项目里获取结果", "提示", {
              confirmButtonText: "前往我的项目",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$message({
                  type: "success",
                  message: "前往我的项目",
                });
              })
              .catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '已取消删除'
                // });
              });
          }
        }, 1000);
        this.$post("/upload/" + this.$route.query.pid, this.$props.params)
          .then((response) => {
            // 处理上传成功后的操作
            clearInterval(t);
            this.loadinginstace.close();
            // console.log("上传成功", response);
            this.modalOpen = false;
            if (
              response.data.error == "User Logged in elsewhere" ||
              response.data.error == "User Not Login"
            ) {
              this.$message({
                showClose: false,
                message: response.data.error,
                type: "error",
              });
              this.$router.push("/");
            } else {
              if (
                response.data.error.length > 0 &&
                response.data.error !== "User Logged in elsewhere"
              ) {
                this.$message({
                  showClose: false,
                  message: response.data.error,
                  type: "error",
                });
              } else if (response.data.error.msg) {
                this.$message({
                  showClose: false,
                  message: response.data.error.msg,
                  type: "error",
                });
              } else {
                this.listsData = response.data;
                this.$emit("handleSuccess", this.listsData);
              }
            }
          })
          .catch((error) => {
            clearInterval(t);
            if (xhr) {
              xhr.abort(); // Abort the request if it's still ongoing
            }
            this.loadinginstace.close();
          });
      } else {
        this.$message({
          showClose: true,
          message: "仅限会员使用",
          type: "warning",
        });
        // 使用 setTimeout 设置延时
        setTimeout(() => {
          // 设置要跳转的目标路由路径
          this.$router.push("/PersonalCenter"); // 替换为你的目标路由路径
        }, 2000); // 3000毫秒（即3秒）的延时
      }
    },
    //数据处理
    dataHandle() {
      this.pushData(this.data1, this.xmldata, 15000, 26);
      this.pushData(this.data2, this.xmldata1, 15000, 26);
      this.pushData(this.data3, this.xmldata2, 15000, 26);
    },

    pushData(dataArray, xmlData, targetLength, minLength) {
      dataArray.push(...xmlData);

      if (dataArray.length < targetLength) {
        const itemsToAdd = targetLength - dataArray.length;
        for (let i = 0; i < itemsToAdd; i++) {
          dataArray.push(Array(dataArray[0].length).fill(""));
        }
      }

      if (dataArray[0].length < minLength) {
        dataArray.forEach((item) => {
          for (let i = item.length; i < minLength; i++) {
            item.push("");
          }
        });
      }
    },

    clear(num) {
      const dataArray =
        num === 0 ? this.data1 : num === 1 ? this.data2 : this.data3;

      dataArray.forEach((items) => {
        items.forEach((item, i) => {
          if (item !== "" || item == 0) {
            this.$set(items, i, "");
          }
        });
      });
    },
    // // 复制粘贴数据的逻辑
    handleClick(tab) {
      // console.log(tab.index*1+1)
    },

    // 拖拽效果
    getReal(el, type, value) {
      let temp = el;
      while (temp !== null && temp.tagName !== "BODY") {
        if (eval(`temp.${type}`) === value) {
          el = temp;
          return el;
        }
        temp = temp.parentElement;
      }
      return el;
    },
    doDown(event) {
      const el = this.getReal(event.target, "className", "resizeMe");
      if (el === null) return;

      const dir = this.getDirection(el);
      if (dir === "") return;

      this.theobject = {
        el,
        dir,
        grabx: event.clientX,
        graby: event.clientY,
        width: el.offsetWidth,
        height: el.offsetHeight,
        left: el.offsetLeft,
        top: el.offsetTop,
      };

      event.returnValue = false;
      event.cancelBubble = true;
    },
    doUp() {
      if (this.theobject !== null) {
        this.theobject = null;
      }
    },
    doMove(event) {
      if (this.theobject !== null) {
        const { dir, el } = this.theobject;
        const { clientX, clientY } = event;
        const xMin = 420; // The smallest width possible
        const xMax = document.documentElement.clientWidth - 250; // The largest width possible

        if (dir.indexOf("w") !== -1) {
          el.style.left = `${Math.max(
            Math.min(
              this.theobject.left + clientX - this.theobject.grabx,
              this.theobject.left + this.theobject.width - xMin
            ),
            this.theobject.left - xMax + this.theobject.width
          )}px`;
          el.style.width = `${Math.max(
            xMin,
            Math.min(
              this.theobject.width - clientX + this.theobject.grabx,
              xMax
            )
          )}px`;
        }

        event.returnValue = false;
        event.cancelBubble = true;
      }
    },
    getDirection(el) {
      const xPos = event.offsetX;
      const yPos = event.offsetY;
      const offset = 8; // The distance from the edge in pixels
      let dir = "";
      if (xPos < offset) dir += "w";
      return dir;
    }, //
    // 放大表格
    incre(num) {
      let wid = document.querySelectorAll(".resizeMe");
      wid[num].style.width = document.documentElement.clientWidth - 250 + "px";
    },
    desc(num) {
      let wid = document.querySelectorAll(".resizeMe");
      wid[num].style.width = 420 + "px";
    },
  },
};
</script>

<style lang="scss" scoped>
.copyAll {
  height: calc(100% - 200px);

  .tips {
    position: absolute;
    top: 0px;
    left: -130px;
    float: left;
    width: 26px;
    display: flex;
    align-items: center;

    .pic {
      height: 26px;
      width: 26px;
    }

    .tit {
      font-size: 12px;
      display: none;
      // margin-left: 6px;
      width: 163px;
      position: absolute;
      left: 25px;
      color: red;
    }
  }

  .tips:hover .tit {
    display: block;
  }

  .copy {
    height: 100%;
    overflow: initial;

    .all {
      height: 100%;

      #testDiv {
        width: 420px;
        height: 100%;
        margin: 0px 0px 0px auto;

        .line {
          height: 99%;
          top: 6.5px;
          width: 14px;
        }
      }
    }

    .incre {
      position: relative;
      top: -45px;
      float: right;
      font-size: 20px;
    }

    .desc {
      position: absolute;
      top: -43px;
      right: 80px;
      font-size: 13px;
    }
  }

  button {
    margin-top: 10px;
    cursor: pointer;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }
}

::v-deep .innerTab {
  position: absolute;
  top: 0;
  left: 20px;
  right: 0;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .ht_master {
    .wtHider {
      width: 100% !important;

      .wtSpreader {
        width: 100%;

        th {
          width: 100px !important;
        }

        td {
          width: 100px !important;
        }

        > .htCore {
          width: 100%;
        }
      }

      // }
    }

    .ht_clone_top {
      width: 100% !important;

      .wtHider {
        width: 100% !important;
      }
    }
  }

  .hot-display-license-info {
    opacity: 0;
    height: 0px;
    overflow: hidden;
  }
}
</style>