<template>
    <div class="item" @click="jump">
        <div class="top">
            <span class="vip"> VIP </span>
            <div class="collect">
                <i></i>
                <span>26</span>
            </div>
        </div>
        <div class="contain">
            <div class="left">
                <img :src="address" alt="" />
            </div>
            <div class="right">
                <div class="top">{{name}}</div>
                <div class="bottom">将表格数据绘制成一个{{name}}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        name:{
            type:String,
            require:true,
        },
        address:{
            type:String,
            require:true,
        },
        path:{
            type:String,
            require:true,
        },
    },
    methods: {
        jump() {
            this.$router.push({
                name:this.path ,
            });
        },
    },
}
</script>
<style lang="scss" scoped>
.item {
    width: 380px;
    height: 160px;
    background-color: rgba(245, 245, 245, 0.8);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 8px 16px 1px #00000014;
    box-sizing: border-box;
    margin-right: 30px;
    margin-bottom: 30px;
    .vip {
        font-size: 12px;
        line-height: 18px;
        border-radius: 17px;
        padding: 1px 7px 0px 7px;
        border: solid 1px #ff9753;
        color: #ff9753;
        background: #ffe9d5;
        left: 10px;
        top: 10px;
    }
    .contain {
        display: flex;
        align-items: center;
        .left {
            width: 80px;
            height: 80px;
            margin-right: 20px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .right {
            .bottom {
            color: #b3b3b3;
            }
        }
    }
}

</style>