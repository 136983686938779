import { render, staticRenderFns } from "./ToTop.vue?vue&type=template&id=486a5fa5&scoped=true"
import script from "./ToTop.vue?vue&type=script&lang=js"
export * from "./ToTop.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486a5fa5",
  null
  
)

export default component.exports